import axiosIns from "@/libs/axios";
import * as toast from '@/custom/class/FunctionClasses/CommonToasts.js'
import axios from "axios";

export function axiosCall({
  payload,
  url,
  method,
  queries,
  loadingOnCursor = false,
  noBody = false,
  treatResponse = resp => resp.data.data
}){
  return new Promise((resolve, reject) => {

    if (url.startsWith('/')){
      url = url.slice(1)
    }
    
    if (queries){
      //URL Query Setup
      let params =  new URLSearchParams(queries)
      url = url.concat(params.toString())
    }

    let config = {
      headers: {
        "x-api-url": url,
        session: `Session ${localStorage.getItem("sessionKey")}`,
      },
    };
    
    if (loadingOnCursor){
      //sets loading cursor if set to do so 
      document.body.setAttribute('style', 'cursor: wait !important');
    }

    if (!['get','delete','post','put','patch'].includes(method)){ //throws an error if using improper method
      reject('error: improper request method')
    }
    
    let p = { data: payload }
    if (noBody){
      p = undefined
    }
    let axiosParams = ["/", p ,config]
    
    if (!payload){
      axiosParams = ["/", config]
    }

    axiosIns[method](...axiosParams)
    .then((resp) => {
      if (!resp.data || resp.data.error == null || resp.data.error != false){
        reject(resp)
      }

      if (resp.status == 403){
        toast.commonToast('403')
        
        // localStorage.removeItem("sessionKey")
        // vue.$router.push({ name: 'login'})
      } 
      if (resp.status == 200 && !resp.data.error) {
        
        let r = treatResponse(resp)
        resolve(r)

      } else {
        throw resp;
      }
    })
    .catch((error) => {
    
      if (error?.response?.status == 403){
        toast.commonToast('403')
        
        // localStorage.removeItem("sessionKey")
        // vue.$router.push({ name: 'login'})
      }
      reject(error);
    })
    .finally(()=>{
      if (loadingOnCursor){
        document.body.setAttribute('style', 'cursor: undefined');
      }
    })
  })
}


//Same as axiosCall but for the new API
export function apiCall({
  url,
  method,
  payload,
  queries,
  headers = {},
  treatResponse = resp => resp.data,
  loadingOnCursor = false,
}){
  return new Promise((resolve, reject) => {

    if (url.startsWith('/')){
      url = url.slice(1)
    }

    const BASE_URL =  process.env.VUE_APP__API_V2_URL;
    
    // --- concatenates Queries to Url ---
    if (queries){

      //removes undefined values 
      Object.keys(queries).forEach(key => queries[key] === undefined ? delete queries[key] : {});

      //URL Query Setup
      let params =  new URLSearchParams(queries)
      if (! (url.endsWith('?')) ){
        url = url + '?'
      }
      url = url.concat(params.toString())
    }
    // -----------------------------------
    url = BASE_URL + url 

    let requestHeaders = {
      ...headers,
      "Authorization":  'Bearer ' + localStorage.getItem("sessionKey")
    }

    if (loadingOnCursor){
      //sets loading cursor if set to do so 
      document.body.setAttribute('style', 'cursor: wait !important');
    }
    axios({
      method: method,
      url: url,
      data: payload,
      headers: requestHeaders
    })
      .then((resp)=>{
        if ((resp.status = 200) && (!resp.data.code || resp.data.code == 200)) { 
          resp = treatResponse(resp)
          
          resolve(resp)
        } else if (resp.status == 403){
          toast.commonToast('403')
          console.error(resp)
          reject(resp)
        } else {
          console.error(resp)
          reject(resp)
        }
      })
      .catch((err)=>{
        if (err?.response?.data?.data?.message == 'TOKEN_EXPIRED'){
          window.location.reload()
        }
        reject(err)
      })
      .finally(()=>{
        if (loadingOnCursor){
          document.body.setAttribute('style', 'cursor: undefined');
        }
      })
  })
}
