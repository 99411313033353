import {axiosCall} from '@/store/AxiosCaller.js'
import Vue from 'vue'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    templates: undefined,
  },
  getters: {
    getTemplates: (state) => {
      return state.templates
    },
  },
  mutations: {
    SET_TEMPLATES(state, payload){
      state.templates = payload
    },  
    ADD_TEMPLATE(state, payload){
      if (Array.isArray(state.templates)){
        state.templates.push(payload)
      }
    },
    UPDATE_TEMPLATE(state, payload){
      delete payload.middleware_hash

      if (Array.isArray(state.templates)){
        let idx = state.templates.findIndex(el => el.id == payload.helper_id)
        if (idx > -1){
          let r = state.templates[idx]
          r = {...r , ...payload}
          Vue.prototype.$set(state.templates, idx, r)
        }
      }
    },
    REMOVE_TEMPLATE(state, payload){
      if (Array.isArray(state.templates)){
        let idx = state.templates.findIndex(el => el.id == payload.id)
        if (idx > -1){
          state.templates.splice(idx, 1)
        } 
      }
    }
  },

  actions: {
    fetchTemplates({commit}, payload){
      axios({
        method: 'get',
        url: 'https://instance.fique.online/webhook/merge/e9bee10a-a93f-46cd-a49b-0659067ef57c/ListHelpers/fadda687fabead141eedfac0e505f1b179ad6c6a539c8811f3b385ef49a6ea1b2e4aed2d7b',
        params:{
          userId: payload.userId
        }
      })
        .then((resp)=>{
          if ( Array.isArray(resp.data) ){
            commit('SET_TEMPLATES', resp.data)
          }
        })
    },
    fetchTemplateById({commit}, payload){
      return axios({
        method: 'get',
        url: 'https://instance.fique.online/webhook/merge/e9bee10a-a93f-46cd-a49b-0659067ef57c/GetHelperByID/1dab8683de7926b753166fe422c71520d79e9ce72b3460b2ca65f13bb927bbee0aab9ee790',
        params:{
          helper_id: payload.id
        }
      }).then((resp)=>{
        return resp.data
      })
    },
    createTemplate({commit}, payload){
      return axios({
        method: 'post',
        url: 'https://instance.fique.online/webhook/e9bee10a-a93f-46cd-a49b-0659067ef57c/CreateHelper/6b305ebef2b2b1f5963d9e691a25312c1c30d06c07fcecbf4910c38949080886de7005929c',
        data: payload,
      }).then((resp)=>{
        if(resp.data?.S?.success){
          let r = resp.data.S.data
          delete r.middleware_hash
          commit('ADD_TEMPLATE', r)
          return r
        } else {
          throw(resp)
        }
      })
    },
    updateTemplate({commit}, payload){
      return axios({
        method: 'put',
        url: 'https://instance.fique.online/webhook/e9bee10a-a93f-46cd-a49b-0659067ef57c/UpdateHelper/e7736a05a1fc815c70f8074194d1b21f043b638d3f1207e7ed9182b26929d7d327e3ae1682',
        data: {
          helper_id: payload.helper_id,
          name: payload.name,
          description: payload.description,
          middleware_hash: payload.middleware_hash,
          categories: payload.categories,
          author_name: payload.author_name
        }
      }).then((resp)=>{
        if (resp.data?.S?.success){
          commit('UPDATE_TEMPLATE', payload)
          return resp.data
        } else {
          throw(resp)
        }
      })
    },
    deleteTemplate({commit}, payload){
      return axios({
        method: 'delete',
        url: 'https://instance.fique.online/webhook/merge/e9bee10a-a93f-46cd-a49b-0659067ef57c/DeleteHelper/a961b9dfeac2a204c65967d7a886fd9bb93408a44878f8b53dcefe6726a15df03320101c57',
        params:{
          helper_id: payload.id
        }
      }).then((resp)=>{
        commit('REMOVE_TEMPLATE',payload)
        return resp.data
      })
    }
  }
}
