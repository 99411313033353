import moment from "moment"
import Store from '@/store'

//======== START DATE FUNCTIONS ======================
export function getDate(dateTime = null){
  if (!dateTime){
    return null
  }
  let d = new Date(dateTime)
  return d.toISOString().slice(0, 10)
}

export function formatDate(date){
  let m = new moment(date)
  return m
}
//====================== START DATE FUNCTIONS ======================

//====================== START ARRAY FUNCTIONS ======================
export function shuffleArray(array){

  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  return array;
}
export function getRandomItem(list, amount=1){

  let r = structuredClone(list);
  r = shuffleArray(r)
  if (amount != 1){
    let l = []
    for (let i=0; i < amount; i++){
      l.push(r[i])
    }
    return l;

  } else {
    return r[0];
  }
}
//====================== END ARRAY FUNCTIONS ======================


//====================== START URL FUNCTIONS ======================
export function getQueriesFromUrl(url){
  if (!url) {
    return {}
  }
  let query = url.split('?')[1]

  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
        }, {}
      )
    : {}
}
//====================== END URL FUNCTIONS ========================


//====================== START IMAGE FUNCTIONS ======================
export function pickAndSaveImg(identifier){
  let input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*'

  input.onchange = e => { 
    let file = e.target.files[0]; 

    let reader = new FileReader();
    reader.readAsDataURL(file); 
    reader.onload = readerEvent => {
      let base64 = reader.result
      
      base64 = base64.split(',')[1]

      Store.dispatch('saveImage',{identifier: identifier, base64: base64} )
    }
  }

  input.click();
}
//====================== END IMAGE FUNCTIONS ==========================

//====================== START NOTIFICATION FUNCTIONS ======================
export function notify(content) {
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notification");

  } else if (Notification.permission === "granted") {
    new Notification(content);

  } else if (Notification.permission !== "denied") {
    
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        new Notification(content);
      }
    });

  }
}
//====================== END NOTIFICATION FUNCTIONS ======================
