import axiosIns from "@/libs/axios";
import moment from "moment";
import router from "@/router";
import EventBus from "@/custom/eventBus";
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall } from "../AxiosCaller";

export default {
  state: {
    confirmModalContent: false,
    currentOrg: null,
    currentOrgUser: null,
    modalTitle: null,
    orgAction: null, 
    selectedOrganization: null,
    userOrganizations: undefined,
  },
  getters: {
    confirmModalContent: (state) => {
      return state.confirmModalContent;
    },
    getCurrentOrg: (state) => {
      return state.currentOrg;
    },
    getCurrentOrgUser: (state) => {
      return state.currentOrgUser;
    },
    getOrgAction: (state) => {
      return state.orgAction;
    },
    modalTitle: (state) => {
      return state.modalTitle;
    },
    getSelectedOrganization: (state) => {
      return state.selectedOrganization;
    },
    getUserOrgs: (state) => {
      return state.userOrganizations
    }
  },
  mutations: {
    SET_CONFIRM_MODAL_CONTENT(state, data) {
      state.confirmModalContent = data;
    },
    SET_CURRENT_ORG(state, data) {
      state.currentOrg = data
    },
    SET_CURRENT_ORG_USER(state, data) {
      state.currentOrgUser = data
    },
    SET_CURRENT_ORG_ACTION(state, data) {
      state.currentOrgUser = data
    },
    SET_SELECTED_ORGANIZATION(state, payload) {
      state.selectedOrganization = payload
    },
    PUT_USER_ORGANIZATIONS(state, payload){
      state.userOrganizations = payload
    }
  },
  actions: {
    setOrgAction({ commit }, payload) {
      commit('SET_CURRENT_ORG_ACTION', payload);
    },
    setSelectedOrganization({ commit, dispatch }, payload) {
      commit('SET_SELECTED_ORGANIZATION', payload);
      
      if (localStorage.getItem('selectedOrganization') != payload){
        // ============ RESET STATE ===================
        dispatch('connection/clearStoredConnections')
        // ============ RESET STATE ===================

      }
      
    },
    async getOrganizationByLoggedUser({ commit, dispatch }, data = null) {
      
        return axiosCall({
          url: await dispatch('resolveFilterUrl', { url: `/central/organization/by/user`, filters: data }),
          method: 'get'
        }).then((resp)=>{
          let orgsInfo = [];

          // Orgs Info normzalized data // 
          resp.items.map(function (value, key) {
            orgsInfo.push(value.organization)
            orgsInfo[key].created_at = (moment(value.created_at).format('LLL'))
            orgsInfo[key].created_by = (value.organization.created_by.fullname)
            orgsInfo[key].created_by_email = (value.created_by.email)
            orgsInfo[key].role = (value.enum_role.label)
            orgsInfo[key].role_id = (value.enum_role.id)
          })

          orgsInfo['totalItems'] = resp.total_items
          
          commit('PUT_USER_ORGANIZATIONS', orgsInfo)

          return(orgsInfo);
        })
    },
    addOrganization(_ , payload) {
      let postData = {
        data: {
          name: payload.data.newOrgName,
        },
      };
      let config = {
        headers: {
          "x-api-url": "/central/organization",
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              EventBus.$emit('reloadOrg');

              resolve(resp.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    fetchOrgRoles({ commit }) {
      let config = {
        headers: {
          "x-api-url": "/enum/auth/role",
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    deleteOrgById({ commit }, payload) {
      let config = {
        headers: {
          "x-api-url": `/central/organization/${payload.item.id}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              EventBus.$emit('reloadOrg');

              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    addUserToOrganization(_ , payload) {
      let postData = {
        data: {
          user: payload.data.newUser.id,
          enum_role: payload.data.role,
          organization: this.getters.getCurrentOrg.id,
        },
      };
      let config = {
        headers: {
          "x-api-url": "/organization/organization_user",
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    infoOrgById(_) {
      let orgId = this.getters.getCurrentOrg.id;
      router.push({ name: "info-organization", params: { orgId: orgId } });
    },
    getOrganizationById({ commit }, params) {
      return axiosCall({
        url: `/central/organization/${params.orgId}`,
        method: 'get',
        treatResponse: resp => resp
      })
    .then((resp)=>{
      let orgsInfo = [];

      let value = resp.data.data
      // Orgs Info normzalized data // 
      orgsInfo = value
      orgsInfo.created_at = (moment(value.created_at).format('LLL'))
      orgsInfo.updated_at = (moment(value.updated_at).format('LLL'))
      orgsInfo.created_by_email = (value.created_by.email)
      return orgsInfo;
    })
    },
    async getUsersByOrganization({ commit }) {
      let orgId = this.getters.getCurrentOrg.id

      let config = {
        headers: {
          "x-api-url": `/central/organization/${orgId}/users`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              let processedData = [];

              resp.data.data.items.map(function (value, key) {
                processedData.push(value.user);
                processedData[key].created_at = moment(value.created_at).format(
                  "LLL"
                );
                processedData[key].role = value.enum_role.label;
                processedData[key].role_id = value.enum_role.id;
              });

              resolve(processedData);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    async getPlansByOrganization({ commit }, data) {
      let orgId = this.getters.getCurrentOrg.id
      delete data.orgId
      
      let config = {
        headers: {
          "x-api-url": `/central/organization/${orgId}/plans`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              let plansInfo = [];
              resp.data.data.items.map(function (value, key) {
                plansInfo.push(value);
                plansInfo[key].category = value.enum_category.label;
                plansInfo[key].status = value.active ? "Active" : "Inactive";
                plansInfo[key].created_at = moment(value.created_at).format(
                  "LLL"
                );
                plansInfo[key].updated_at = moment(value.updated_at).format(
                  "LLL"
                );
              });

              resolve(plansInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    async getServersByOrganization({ commit, dispatch, getters }, data = null) {
      let orgId = localStorage.getItem('selectedOrganization')

      let url = await dispatch("resolveUrl", { url: `/central/organization/${orgId}/servers`, params: data })

      return axiosCall({
        url: url,
        method: 'get'
      })
      .then((resp)=>{
        let serversInfo = [];

        resp.items.map(function (value, key) {
          serversInfo.push(value)
          serversInfo[key].created_at = (moment(value.created_at).format('LLL'))
          serversInfo[key].status = (value.enum_status.label)
          serversInfo[key].type = (value.enum_type.label)
        })

        return serversInfo;
      })
    },
    editOrganizationById(_ , payload) {
      let organization = this.getters.getCurrentOrg.id;

      let postData = {
        name: payload.data.editOrgName,
      };
      
      return axiosCall({
        url: `/central/organization/${organization}`,
        payload: postData,
        method: "put",
        treatResponse: resp => resp
      })
      .then((resp)=>{
        EventBus.$emit('reloadOrg');
        return resp.data
      })
    },
    editOrganizationUser(_ , payload) {
      let organization = this.getters.getCurrentOrg.id;
      let user = this.getters.getCurrentOrgUser.id;

      let postData = {
        data: {
          enum_role: payload.data.role,
        },
      };
      let config = {
        headers: {
          "x-api-url": `/organization/organization_user/${organization}/${user}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .put("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },

    setModalTitle({ commit }, modalTitle) {
      commit("SET_MODAL_TITLE", modalTitle);
    },
    deleteUserFromOrgById(_, payload) {
      let organization = payload.orgId
      let user = payload.item.id

      let config = {
        headers: {
          'x-api-url': `/organization/organization_user/${organization}/${user}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getOrganizationByName({ commit }, data) {
      let config = {
        headers: {
          "x-api-url": `/central/organization/by/user?value=${data ? data : data}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              let orgsInfo = [];

              // Orgs Info normzalized data // 
              resp.data.data.items.map(function (value, key) {
                orgsInfo.push(value.organization)
                orgsInfo[key].created_at = (moment(value.created_at).format('LLL'))
                orgsInfo[key].created_by = (value.organization.created_by.fullname)
                orgsInfo[key].role = (value.enum_role.label)
                orgsInfo[key].role_id = (value.enum_role.id)
              })

              resolve(orgsInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getOrganizationLogs({ commit }, {orgID, period}){
      //period format -> 1/2023
      //no leading zero -> ex: for Feb use 2 instead of 02,
      return axiosCall({
        url: `/organization/${orgID}/charger/logs?period=${period}`,
        method: "get"
      })
    }
  },
};
