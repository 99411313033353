import {axiosCall, apiCall} from '@/store/AxiosCaller.js'

export default {
  namespaced: true,
  state: {
    notifications: undefined,
    meta:{
      page: 1,
      total: undefined,
      isLoading: false,
      searchTerm: undefined
    }
  },
  getters: {
    getNotifications: (state) => {
      return state.notifications
    },
    getMeta: (state) => {
      return state.meta
    },
  },
  mutations: {
    SET_NOTIFICATIONS(state, payload){
      state.notifications = payload?.data
      state.meta.total = payload?.meta.total
      if (!payload){ // if used to clear state
        state.meta.page = 1
      } else { // if used to actually set data
        state.meta.page = payload.meta.current_page + 1
      }
    },
    SET_NOTIFICATION_READ(state, payload){
      let notifications = structuredClone(state.notifications)

      payload.forEach((id)=>{
        let idx = notifications.findIndex(el => el.id == id)
        let t = notifications[idx]
        t.visualization = true
        state.notifications[idx] = t
      })

    }, 
    PUSH_NOTIFICATIONS(state, payload){
            
      let notifications = structuredClone(state.notifications)
      if (Array.isArray(notifications)){
        notifications.push(...payload.data)
        
        state.notifications = notifications
      }
      state.meta.page = payload.meta.current_page + 1
    }
  },
  actions: {
    fetchNotifications({commit, state}, payload){
      
      
      if (payload.resetState == true){
        commit('SET_NOTIFICATIONS', undefined)
        state.meta.searchTerm = payload?.searchTerm || undefined
      }


      state.meta.isLoading = true

      return apiCall({
        method: 'get',
        url: `notification/user/index/${payload.userId}`,
        queries:{
          per_page: 10,
          page: state.meta.page,

          search: payload?.searchTerm || state.meta.searchTerm || undefined
        }
      })
        .then((resp)=>{
          state.meta.isLoading = false

          if (payload.resetState == true){
            commit('SET_NOTIFICATIONS', resp)
          } else {
            commit('PUSH_NOTIFICATIONS', resp)
          }
        })
    },
    visualizeNotifications({commit, dispatch}, payload){
      commit('SET_NOTIFICATION_READ', payload.notificationIds)
      dispatch('updateUserNotifications', payload, {root:true})

      apiCall({
        method: 'post',
        url: `notification/user/notification/visualization`,
        payload: {
          notification: payload.notificationIds
        }
      }).then((resp)=>{
        return resp
      })
    },
    
  }
}
