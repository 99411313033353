//src/libs/axios.js

import Vue from 'vue'

import axios from 'axios'


// =-=-=-=-=-=-=-=-= PRODUCTION API =-=-=-=-=-=-=-=-=
// const axiosIns = axios.create({
//   baseURL: "https://api.fique.online/v1",
//   headers: {
//     'Content-Type': 'application/json',
//     'x-api-key': "Token 7f926612-2a9e-4b74-b0e5-9b22a1c3525f"
//   }
// })
// =-=-=-=-=-=-=-=-= PRODUCTION API =-=-=-=-=-=-=-=-=


let baseUrl = process.env.VUE_APP__API_V1_URL

// if (baseUrl.endsWith('/')){
//   baseUrl = baseUrl.slice(0,-1)
// }

const axiosIns = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.VUE_APP__API_V1_TOKEN
  }
})



axiosIns.interceptors.request.use((config) => {
  let cfg = undefined
  switch (true) {
    case config.method == 'get' : {
      cfg = config
    } break;
    default : {
      cfg = {
        ...config,
        data: config.data?.data ? config.data.data : config.data
      }
    }
  }

  let path = config.headers["x-api-url"]

  if (config.baseURL.endsWith('/') && path.startsWith('/') ){
    path = path.slice(1)
  } 
  
  let url = `${config.baseURL}${path}`
  cfg.url = url
  cfg.headers["Authorization"] = cfg.headers["session"]

  delete config.headers["x-api-url"]
  delete cfg.headers["session"]

  return cfg
}, (err) => console.error(err));


Vue.prototype.$http = axiosIns

export default axiosIns
