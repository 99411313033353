import { axiosCall } from "../AxiosCaller";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    storedRecords: null  
  },
  getters: {
    getStoredRecords: (state) => {
      return state.storedRecords
    }
  },
  mutations: {
    PUT_STORED_RECORDS(state, payload) {
      state.storedRecords = payload;
    },
    ADD_RECORD(state, payload){
      state.storedRecords.push(payload)
    },
    PATCH_RECORD(state, payload){ 
      if (Array.isArray(state.storedRecords)){
       const idx = state.storedRecords.findIndex(el => el.id == payload.id)

       if (idx > -1){
        Vue.set(state.storedRecords, idx, payload)
       } 
      }
    },
    DELETE_RECORD(state, recordId){
      if (Array.isArray(state.storedRecords)){
        const idx = state.storedRecords.findIndex(el => el.id == recordId)
 
        if (idx > -1){
          state.storedRecords.splice(idx, 1)
        } 
       }
    },
  },
  actions: {
    getRecords({commit}, payload) {
    
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/internal_database`,
        method: 'get',
      })
      .then((resp)=>{
        commit('PUT_STORED_RECORDS', resp)
        return resp
      })
      
    },
    createRecord({commit}, payload) {
      let body = {
        key: "",
        value: `{}`
      }
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/internal_database`,
        method: 'post',
        payload: body,
      })
      .then((resp)=>{
        commit("ADD_RECORD", resp)
        return resp
      })

    },
    editRecordKey({commit}, payload){
      let body = {
        new_value: payload.newValue
      }
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/internal_database/${payload.recordId}/key`,
        method: "patch",
        payload: body
      })
      .then((resp)=>{
        commit("PATCH_RECORD", resp)
        return resp
      })
    },
    editRecordValue({commit}, payload){
      let body = {
        new_value: payload.newValue
      }
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/internal_database/${payload.recordId}/value`,
        method: "patch",
        payload: body
      })
      .then((resp)=>{
        commit("PATCH_RECORD", resp)
        return resp
      })
    },
    deleteRecord({commit}, payload){
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/internal_database/${payload.recordId}`,
        method: "delete",
      })
      .then((resp)=>{
        commit("DELETE_RECORD", payload.recordId)
        return resp
      })

    }
  }
};