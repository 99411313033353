
import axios from "axios";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    releases: undefined,
    currentRelease: undefined  
  },
  getters: {
    getReleases: (state) => {
      return state.releases
    },
    getCurrent: (state) => {
      return state.currentRelease
    }
  },
  mutations: {
    PUT_RELEASES(state, payload) {
      state.releases = payload.releases
      state.currentRelease = payload.current
    },
    SET_CURRENT(state, payload){
      state.currentRelease = payload
    }
  },
  actions: {
    listReleases({commit, state}, payload){
      if (Array.isArray(state.releases)){
        return
      }

      return axios({
        method: 'get',
        url: 'https://instance.fique.online/webhook/merge/d46b368f-358f-44c8-861c-5082d7438dfc/listReleases/95fccb70e368662c19da0d48015c6a0b4afe84bd8c6d53e6ca82001b5f448854ba2c2bf829'
      })
        .then((resp)=>{{
          commit('PUT_RELEASES', resp.data)
          return resp
        }})
    },
    getReleaseDoc({commit, state}, payload){
      return axios({
        method: 'post',
        url: 'https://instance.fique.online/webhook/merge/d46b368f-358f-44c8-861c-5082d7438dfc/getRelease/30bebba9f685cf4b1f07a8ddaee3c6fa4696d8509f69cccfdf4b10e8bc7c79bb76af7b7e1c',
        data:{
          release: payload.release
        }
      })
        .then((resp)=>{{
          commit("SET_CURRENT", resp.data)
          return resp
        }})
    }
  }
};