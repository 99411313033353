
export default {
  namespaced: true,
  state: {
    totalSidebarsOpened: 0,
    reactiveEnvFlag: 1,
    terminalRunTrigger: undefined,
    fluxPressedkeys: [], //always lower case - ex: 'shift' , 'ctrl' , 'v'    
    fluxSearchInfo: {
      matched: [],
      focusingIndex: 1, 
      // Match Ex: {
      //    middleware: mid.id,
      //    agent: agent.id,
      //    matchType: -> 'agentSource' | 'agentValue' | 'agentType' | 'middlewareName' | 'middlewareType'
      //}
    },
    reactiveTerminalFlag: 1,
    getEventChangedTrigger: 0,
  },
  getters: {
    getEventChangedTrigger: (state) => {
      return state.getEventChangedTrigger
    },
    hasSidebarOpen: (state) => {
      return state.totalSidebarsOpened > 0;
    },
    fluxSearchMatched: (state) => {
      return state.fluxSearchInfo.matched
    },
    fluxSearchIndex: (state) => {
      return state.fluxSearchInfo.focusingIndex
    },
    fluxSearchMiddlewareStack: (state) => {
      let match = state.fluxSearchInfo.matched[state.fluxSearchInfo.focusingIndex-1]
      let r  = []
      if (match){
        r = match.middlewareStack
      }
      return r
    },
    terminalRunTrigger: (state) => {
      return state.terminalRunTrigger
    },
    getFluxPressedKeys: (state) => {
      return state.fluxPressedkeys
    },
    getReactiveEnv: (state) =>{
      return state.reactiveEnvFlag
    },
    getReactiveTerminal: (state) =>{
      return state.reactiveTerminalFlag
    }
  },
  mutations: {
    SET_SIDEBAR_STATE(state, isOpen) {
      if (isOpen){
        state.totalSidebarsOpened = state.totalSidebarsOpened + 1
      } else {
        state.totalSidebarsOpened = state.totalSidebarsOpened - 1
      }

      if (state.totalSidebarsOpened < 0){
        state.totalSidebarsOpened = 0
      }
    },
    SET_NO_SIDEBAR_OPEN(state, isOpen) {
      state.totalSidebarsOpened = 0
    },
    SET_FLUX_SEARCH_MATCHES(state, matches){
      state.fluxSearchInfo.matched = matches
    },
    SET_FLUX_SEARCH_INDEX(state, index){
      if (index == undefined){
        index = state.fluxSearchInfo.focusingIndex
      }

      let temp = state.fluxSearchInfo.matched;
      state.fluxSearchInfo.matched = []
      state.fluxSearchInfo.matched = temp
      
      state.fluxSearchInfo.focusingIndex = 0
      
      state.fluxSearchInfo.focusingIndex = index
    },
    SET_TERMINAL_RUN_TRIGGER(state, data){
      state.terminalRunTrigger = data
    },

    SET_FLUX_KEY_DOWN(state, data){
      if (!state.fluxPressedkeys.includes(data)){    
        state.fluxPressedkeys.push(data)
      }
    },
    SET_FLUX_KEY_UP(state, data){
      const idx = state.fluxPressedkeys.findIndex(el => el == data)
      if (idx > -1){
        state.fluxPressedkeys.splice(idx, 1)
      }      
    },
    FLUX_UNREGISTER_KEYS(state, data){
      state.fluxPressedKeys = []
    },
    
  },
  actions: {
    setSidebarState({commit, state}, isOpen) {
      commit("SET_SIDEBAR_STATE", isOpen);
    },
    setNoSidebarOpen({commit}){
      commit("SET_NO_SIDEBAR_OPEN");
    },
    setMatchedFluxSearch({commit, state}, matches) {
      commit("SET_FLUX_SEARCH_MATCHES", matches);
    },
    setMatchedFluxIndex({commit, state}, index) {
      commit("SET_FLUX_SEARCH_INDEX", index);
    },
    setTerminalRunTrigger({commit, state}, data) {
      commit("SET_TERMINAL_RUN_TRIGGER", data);
    },

    fluxKeyDown({commit, state}, key) {
      commit("SET_FLUX_KEY_DOWN", key);
    },
    fluxKeyUp({commit, state}, key) {
      commit("SET_FLUX_KEY_UP", key);
    },
    unregisterAllKeys({commit, state},) {
      commit("FLUX_UNREGISTER_KEYS");
    },
    setReactiveEnv({state}){
      state.reactiveEnvFlag = state.reactiveEnvFlag + 1
    },
    selectedEventChanged({state}){
      state.getEventChangedTrigger = state.getEventChangedTrigger + 1
    }
  }
}