export default [
  {
    path: "/organization",
    name: "organization",
    component: () => import("@/views/pages/organization/Organization.vue"),
    meta: {
      title: "FiqOn - Organization",
      refreshTable: true,
      event: 'reloadOrg',
      pageTitle: "Organization",
      breadcrumb: [
        {
          text: "Organization",
          active: true,
        },
      ],
    },
  },
  {
    path: "/organization/info-organization/:orgId",
    name: "info-organization",
    component: () => import("@/views/pages/organization/InfoOrganization.vue"),
    meta: {
      title: "FiqOn - Info Organization",
      pageTitle: "Info Organization",
      refreshInput: true,
      breadcrumb: [
        {
          text: "Organization",
          to: { name: "organization" }
        },
        {
          text: "Info Organization",
          active: true,
        },
      ],
    },
  },
  {
    path: "/organization/dashboard",
    name: "integrations-dashboard",
    component: () => import("@/views/pages/organization/IntegrationsDashboard.vue"),
    meta: {
      title: "FiqOn - Integrations Dashboard",
      pageTitle: "Integrations Dashboard",
      hideNavbar: true,
      breadcrumb: [
        {
          text: "Integrations Dashboard",
          active: true,
        },
      ],
    },
  },
  {
    path: "/organization/usage",
    name: "organization-usage",
    component: () => import("@/views/pages/organization/OrganizationUsage.vue"),
    meta: {
      title: "FiqOn - Organization Usage",
      pageTitle: "Organization Usage",
      hideNavbar: true,
      breadcrumb: [
        {
          text: "Organization Usage",
          active: true,
        },
      ],
    },
  }
];
