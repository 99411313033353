import Vue from "vue";
import VueRouter from "vue-router";
import login from "@/router/routes/login";
import pages from "@/router/routes/pages";
import profile from "@/router/routes/profile";
import transmission from "@/router/routes/transmission"
import services from '@/router/routes/services'
import events from '@/router/routes/events'
import integrateApps from '@/router/routes/integrateApps'
import integrator from '@/router/routes/integrator'
import connection from '@/router/routes/connection'
import oauth from '@/router/routes/oauth.js'
import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn } from "@/auth/utils"; //getUserData
import store from "@/store";
import organization from "./routes/organization";
import plan from "./routes/plan";
import server from "./routes/server";
import environments from "./routes/environments";
import compiled from "./routes/compiled";
import internalStorage from "@/router/routes/internalStorage.js"

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "home" } },
    ...login,
    ...pages,
    ...profile,
    ...organization,
    ...plan,
    ...server,
    ...transmission,
    ...services,
    ...events,
    ...environments,
    ...compiled,
    ...integrateApps,
    ...integrator,
    ...connection,
    ...oauth,
    ...internalStorage,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach(async (to, _, next) => {
  
  const urlParams = new URLSearchParams(window.location.search);

  let jwtToken = "";
  next()
  Vue.nextTick(() => {
    document.title = to.meta.title || 'FiqOn';
  });

  const isLoggedIn = await isUserLoggedIn();
  

  const verifiedPhone = store.getters.getVerifiedPhone;
  const verifiedEmail = store.getters.getVerifiedEmail;
  store.dispatch('isDebugMode')
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn && !localStorage.getItem('sessionKey')) {
      if(urlParams.get('token')){
        jwtToken = urlParams.get('token');
        localStorage.setItem('sessionKey', jwtToken);
        this.router.push({name: 'transmission'})
      }
      return next({ name: "login" });
    }

    // If logged in => not authorized
    if (isLoggedIn){
      return next({ name: "not-authorized" });
    }
  } else if (isLoggedIn) {
    if (!verifiedEmail ? verifiedPhone : !verifiedPhone) {
      if (!verifiedPhone && to.name != "verify-phone") {
        next({ name: "verify-phone" });
      } else if (!verifiedEmail && to.name != "verify-email") {
        next({ name: "verify-email" });
      } else return next();
    } else if (!verifiedPhone && !verifiedPhone && to.name != "verify-email") {
      next({ name: "verify-email" });
    }
    // // Redirect if logged in
    else if (to.meta.redirectIfLoggedIn && verifiedEmail && verifiedPhone) {
      next(isLoggedIn);
    } 
  } else if (to.meta.resource !== "Auth" && !isLoggedIn) {
    return next({ name: "login" });
  } else return next();
});

// // ? For splash screen
// // Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
  store.commit("SET_IS_ACTIVE", false);
});

export default router;
