import loginModules from '@/store/modules/loginModule'
import organizationModule from '@/store/modules/organizationModule'
import baseModalModule from '@/store/modules/baseModalModule'
import bsTableModule from '@/store/modules/bsTableModule'
import serversModule from '@/store/modules/serversModule'
import profileModule from '@/store/modules/profileModule'
import helpersModule from '@/store/modules/helpersModule'
import plansModule from '@/store/modules/plansModule'
import debugModule from '@/store/modules/debugModule'
import transmissionModule from '@/store/modules/transmissionModule'
import servicesModule from '@/store/modules/servicesModule'
import eventsModule from '@/store/modules/eventsModule'
import environmentsModule from '@/store/modules/environmentsModule'
import compiledModule from '@/store/modules/compiledModule'
import middlewareModule from '@/store/modules/middlewareModule'
import agentModule from '@/store/modules/agentModule'
import enumModule from '@/store/modules/enumModule'
import thirdPartyApps from '@/store/modules/thirdPartyApps'
import integrator from '@/store/modules/integrator'
import schedulerModule from '@/store/modules/schedulerModule'
import agents from '@/store/modules/agents'
import dashboard from '@/store/modules/dashboard'
import transmissionWebhook from '@/store/modules/WebhookModule.js'
import connection from '@/store/modules/ConnectionModule.js'
import internal from "@/store/modules/internalModule.js"
import internalStorage from "@/store/modules/internalStorageModule.js"
import template from "@/store/modules/TemplatesModule.js"
import certificate from '@/store/modules/certificatesModule.js'
import release from "@/store/modules/releasesModule.js"
import notification from "@/store/modules/notificationsModule.js"

export default {
    loginModules,
    organizationModule,
    baseModalModule,
    bsTableModule,
    serversModule,
    profileModule,
    helpersModule,
    plansModule,
    debugModule,
    transmissionModule,
    servicesModule,
    eventsModule,
    environmentsModule,
    compiledModule,
    middlewareModule,
    agentModule,
    enumModule,
    schedulerModule,
    transmissionWebhook,
    connection,
    template,
    internal,
    internalStorage,
    certificate,
    release,
    notification,
    ...dashboard,
    ...integrator,
    ...agents,
    ...thirdPartyApps,
}