import axiosIns from "@/libs/axios";
import moment from "moment";
import router from "@/router";
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall } from "../AxiosCaller";

export default {
  state: {
    currentEvent: null,
    currentEventPermission: null,
    eventAction: null,
    pathIdMap: null,
    transmissionEvents: undefined
  },
  getters: {
    getCurrentEvent: (state) => {
      return state.currentEvent;
    },
    getCurrentEventPermission: (state) => {
      return state.currentEventPermission;
    },
    getEventAction: (state) => {
      return state.eventAction;
    },
    getPathIdMap: (state) => {
      return state.pathIdMap;
    },
    getTransmissionEvents: (state) => {
      return state.transmissionEvents;
    },
  },
  mutations: {
    SET_CURRENT_EVENT(state, payload) {
      state.currentEvent = payload;
    },
    SET_CURRENT_EVENT_PERMISSION(state, payload) {
      state.currentEventPermission = payload;
    },
    SET_EVENT_ACTION(state, payload) {
      state.eventAction = payload;
    },
    SET_PATH_ID_MAP(state, payload) {
      state.pathIdMap = payload;
    },
    SET_EVENTS_IN_TRANSMISSION: (state, payload) => {
      state.transmissionEvents = payload
    },
    PUSH_PATH_ID_MAP(state, payload) {
      state.pathIdMap[payload.path] = payload.id;
    },
    PATCH_EVENT_SAMPLE(state, payload){
      if (Array.isArray(state.transmissionEvents)){
        const idx = state.transmissionEvents.findIndex(el=> el.id == payload.eventID)
        if (idx > -1){
          state.transmissionEvents[idx].object_sample = payload.newValue
        }
      }
    },
    REMOVE_PATH_ID_MAP(state, payload) {
      payload ? delete state.pathIdMap[payload.path] : state.pathIdMap = null
    },
    REMOVE_EVENT_FROM_TRANSMISSION: (state, id) => {
      if (Array.isArray(state.transmissionEvents)){
        let idx = state.transmissionEvents.findIndex(el => el.id == id)
        if (idx > -1){
          state.transmissionEvents.splice(idx, 1)
        }
      }
    },
    INSERT_EVENT_IN_TRANSMISSION: (state, payload) => {
      if (Array.isArray(state.transmissionEvents)){
        state.transmissionEvents.push(payload)
      }
    },
  },
  actions: {
    setCurrentEvent({ commit }, payload) { 
      commit("SET_CURRENT_EVENT", payload);
    },
    setCurrentEventPermission({ commit }, payload) {
      commit("SET_CURRENT_EVENT_PERMISSION", payload);
    },
    setEventAction({ commit }, payload) {
      commit("SET_EVENT_ACTION", payload);
    },
    setPathIdMap({ commit }, payload) {
      commit("SET_PATH_ID_MAP", payload);
    },
    pushMapPathId({ commit }, payload) {
      commit("PUSH_PATH_ID_MAP", payload);
    },
    removePathIdMap({ commit }, payload) {
      commit("REMOVE_PATH_ID_MAP", payload);
    },
    async fetchEventsByTransmissionGroup({ commit, dispatch }, data = null) {
      let transmissionGroup = data.transmissionID
      delete data.transmissionID
      let url = await dispatch("resolveUrl", {
        url: `/${transmissionGroup}/transmission/event`,
        params: data,
      })

      return axiosCall({
        url: url,
        method: 'get'
      })
      .then((resp)=>{
        let eventInfo = [];
        // Orgs Info normzalized data //
        resp.map(function(value, key) {
          eventInfo.push(value);
          eventInfo[key].created_at = moment(value.created_at).format(
            "LLL"
          );
          eventInfo[key].updated_at = moment(value.updated_at).format(
            "LLL"
          );
        });
        eventInfo["totalItems"] = Object.keys(resp).length;
        commit('SET_EVENTS_IN_TRANSMISSION', resp)
        return(eventInfo);
      })
    },
    addEvent({ commit, getters }, payload) {
      let transmissionGroup = payload.transmissionID
      
      if(typeof payload.data.object == 'string' || myVar instanceof String){
        //if Object comes as a String parse it
        payload.data.object = JSON.parse(payload.data.object)
      }

      let data = {
        description: payload.data.description,
        event_identifier: payload.data.identifier,
        enum_retry_id: payload.data.retry_limit.id,
        max_retry: (!payload.data.max_retry || payload.data.max_retry < 0) ? 0 : parseInt(payload.data.max_retry),
        object: payload.data.object ? payload.data.object : {},
        object_sample: payload.data.object ? payload.data.object : {},
      }

      data.object_sample = JSON.stringify(data.object_sample) 

      return axiosCall({
        url:  `/${transmissionGroup}/transmission/event`,
        method: 'post',
        payload: data,
        treatResponse: resp => resp 
      })
      .then((resp)=>{
        commit('INSERT_EVENT_IN_TRANSMISSION',resp.data.data)
        return resp.data;
      })
    },
    patchEventObjectSample({commit}, payload){
      return new axiosCall({
        url: `/${payload.transmissionID}/transmission/event/${payload.eventID}/object_sample`,
        method: 'patch',
        payload: {new_value: payload.newValue}
      })
      .then((resp)=>{
        commit('PATCH_EVENT_SAMPLE', payload)
        return resp
      })
    },
    deleteEventById({commit}, payload) {
      let transmissionGroup = payload.transmissionID
      return new axiosCall({
        url: `/${transmissionGroup}/transmission/event/${payload.item.id}`,
        method: 'delete'
      })
      .then((resp)=>{
        commit('REMOVE_EVENT_FROM_TRANSMISSION', payload.item.id)
        return resp;
      })
    },
    eventInfo(_) {
      let event = this.getters.getCurrentEvent.id;
      router.push({ name: "event-info", params: { eventId: event } });
    },
    getEventbyId({ commit }, payload) {
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/event/${payload.eventId}`,
        method: 'get',
        treatResponse: resp => resp
      })
      .then((resp)=>{
        let eventInfo = [];

        let value = resp.data.data;
        // Orgs Info normzalized data //
        eventInfo = value;
        eventInfo.description = value.value ? value.value : ""
        eventInfo.created_at = moment(value.created_at).format("LLL");
        eventInfo.updated_at = moment(value.updated_at).format("LLL");

        return eventInfo;
      })
    },
    getServicesByEvent({ commit }, payload) {
        
      let transmissionGroup = payload.transmissionID
      delete payload.transmissionID

      let eventID = payload.eventId
      delete payload.eventID
      
      return axiosCall({
        url: `/${transmissionGroup}/transmission/service_event/by/event/${eventID}`,
        method: 'get'
      })
      .then((resp)=>{
        let eventInfo = [];

        resp.map((value, key) => {
          eventInfo.push(value);
          eventInfo[key].id = value.service.id;
          eventInfo[key].identifier = value.service.identifier;
          eventInfo[key].created_at = moment(value.created_at).format(
            "LLL"
          );
          eventInfo[key].updated_at = moment(value.updated_at).format(
            "LLL"
          );
        });

        return eventInfo;
      })
    },
    changeCanReceive(_ , payload) {
      let data= {
        new_value: !payload.item.can_receive,
      }
      
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/service_event/${payload.item.event.id}/${payload.item.service.id}/can_receive`,
        method: 'patch',
        payload: data,
        treatResponse: resp => resp
      })
    },
    changeCanSend(_ , payload) {
      let data = {
        new_value: !payload.item.can_send,
      };

      return axiosCall({
        url: `/${payload.transmissionID}/transmission/service_event/${payload.item.event.id}/${payload.item.service.id}/can_send`,
        method: 'patch',
        payload: data,
        treatResponse: resp => resp
      })
    },
    addEventPermission({ getters }, payload) {      
      let event
      let transmissionGroup = payload.transmissionID
      delete payload.transmissionID

      if (payload.data.event){
        event = payload.data.event
      } else {
        event = getters.getCurrentEvent.id;
      }

      let data = {
        service: parseInt(payload.data.service),
        event: parseInt(event),
      }

      return axiosCall({
        url: `/${transmissionGroup}/transmission/service_event`,
        method: 'post',
        payload: data,
        treatResponse: resp => resp.data
      })
    },
    deleteEventPermission(_ , payload) {
      let transmissionGroup = payload.transmissionID
      delete payload.transmissionID

      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/service_event/${payload.item.event.id}/${payload.item.service.id}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getEventsByTransmissionGroup({ commit }, payload) {
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/event`,
        method: 'get'
      }).then((resp)=>{
        let aux = {
          items: resp
        }
        commit('SET_EVENTS_IN_TRANSMISSION', resp)
        return aux;
      })
    },
    getEnumRetryInfo(_, enum_id) {
      return axiosCall({
        url: `/enum/event/retry/${enum_id}`,
        method: 'get'
      })
    },
    editEvent({ getters }, payload) {
      let transmissionGroup = payload.transmissionID
      let eventId = getters.getCurrentEvent.id;
      let action = getters.getEventAction;
      let lowerAction = action.toLowerCase();
      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/event/${eventId}/${lowerAction}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let patchData =  {
        new_value: payload.data[`newEvent${action}`],
      };

      return axiosCall({
        url: `/${transmissionGroup}/transmission/event/${eventId}/${lowerAction}`,
        method: 'patch',
        payload: patchData,
      })
    },
    editEventEnumRetry({ getters }, payload) {
      let transmissionGroup = payload.transmissionID
      let eventId = getters.getCurrentEvent.id;
      let action = getters.getEventAction;
      let lowerAction = action.toLowerCase();
      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/event/${eventId}/${lowerAction}/${
            payload.data[`newEvent${action}`]
          }`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", {}, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              
              resolve(resp.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    fetchEnumRetry({ commit }) {
      let config = {
        headers: {
          "x-api-url": "/enum/event/retry",
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },

    getEventObjectField({ commit, getters }, payload) {
      let eventId = payload.eventID || getters.getCurrentEvent.id;
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/event_object_field/by/event?value=${eventId}`,
        method: 'get'
      })
      .then((resp)=>{
        //Função que transforma a payload (uma estrutura linear de objetos com informações sobre os campos do objeto) em um JSON

        let data = {};
        let map_parents = [];

        resp.forEach((value) => {
          if (!map_parents[value.parent_id]) {
            map_parents[value.parent_id] = [];
          }

          data[value.id] = value;
          map_parents[value.parent_id].push(value.id);
        });

        let queue = []; //commit('INSERT_TRANSMISSION_IN_SERVICES', resp)
        let obj = {};

        queue.push([null, obj]);

        let parent_path_map = { null: null };
        let path_id_map = {};

        while (queue.length > 0) {
          let top = queue.pop();

          if (map_parents[top[0]] != undefined) {
            map_parents[top[0]].forEach((val) => {
              if (!top[1][data[val]["field_key"]]) {
                top[1][data[val]["field_key"]] = [];
              }

              let parent_path = parent_path_map[top[0]];

              let current_path =
                (parent_path ? `${parent_path}.` : "") +
                data[val]["field_key"];

              if (data[val].enum_data_type_id == "6") {
                path_id_map[current_path] = val;
                current_path += ".0";
                parent_path_map[val] = current_path;
                path_id_map[current_path] = map_parents[val];

                top[1][data[val]["field_key"]] = [{}];
                queue.push([val, top[1][data[val]["field_key"]][0]]);
                return true;
              } else if (
                [7, 8, 9, 10].includes(
                  parseInt(data[val].enum_data_type_id)
                )
              ) {
                top[1][data[val]["field_key"]] = [];
                
              } else if (data[val].enum_data_type_id == 4) {
                top[1][data[val]["field_key"]] = {};
              }

              if (map_parents[val] != undefined) {
                parent_path_map[val] = current_path;
                path_id_map[current_path] = val;
                queue.push([val, top[1][data[val]["field_key"]]]);
              } else if (
                !([4, 7, 8, 9, 10].includes(
                  parseInt(data[val].enum_data_type_id)
                ))
              ) {
                path_id_map[current_path] = val;
                top[1][data[val]["field_key"]] =
                  data[val]["example_value"];
              }
            });
          }
        }

        commit("SET_PATH_ID_MAP", path_id_map);

        return obj; 
      })

    },
    addEventObjectField({ getters, dispatch }, payload) {
      let parentPath = payload.parentPath || null; //MUDAR ISSO ALGUM DIA

      let transmissionGroup = payload.transmissionID
      let eventId = getters.getCurrentEvent.id;
      let parentId = parentPath
        ? parseInt(getters.getPathIdMap[parentPath])
        : null;

      dispatch("resolveEnumType", payload.item.value).then((enum_type_id) => {
        let postData = {
          data: {
            enum_type: enum_type_id,
            example_value: payload.item.value instanceof Object ? JSON.stringify(payload.item.value) : payload.item.value.toString(),
            field_key: payload.item.key,
            event_object_field: parentId,
            event: eventId,
            required: true,
          },
        };
        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/transmission/event_object_field`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };
        return new Promise((resolve, reject) => {
         console.trace(); axiosIns
            .post("/", postData, config)
            .then((resp) => {
              if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
                const parentPath = payload.parentPath
                  ? `${payload.parentPath}.${payload.item.key}`
                  : payload.item.key;

                dispatch("pushMapPathId", {
                  path: parentPath,
                  id: resp.data.data.id,
                }) // falta o retorno ao adicionar nova chave
                  .then(() => {
                    resolve(resp.data);
                  });
              } else {
                throw resp
              }
            })
            .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
              reject(error);
            });
        });
      });
    },
    updateEventObjectField({ getters, dispatch }, payload) {
     
      let fieldsId = getters.getPathIdMap[payload.item.path];

      let parentPath = null;

      if (payload.parentPath != payload.item.path) {
        parentPath = payload.parentPath || null; //MUDAR ISSO ALGUM DIA
      }

      let transmissionGroup = payload.transmissionID
      let eventId = getters.getCurrentEvent.id;
      let parentId = parentPath
        ? parseInt(getters.getPathIdMap[parentPath])
        : null;

      dispatch("resolveEnumType", payload.item.value).then((enum_type_id) => {
        let putData = {
          data: {
            enum_type: enum_type_id,
            example_value: payload.item.value instanceof Object ? JSON.stringify(payload.item.value) : payload.item.value.toString(),
            field_key: payload.item.key,
            event_object_field: parentId,
            event: eventId,
            required: true,
          },
        };
        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/transmission/event_object_field/${fieldsId}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };
        return new Promise((resolve, reject) => {
         console.trace(); axiosIns
            .put("/", putData, config)
            .then((resp) => {
              if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

                dispatch("pushMapPathId", {
                  path: payload.item.path,
                  id: resp.data.data.id,
                }).then(() => {
                  resolve(resp.data);
                });
              } else {
                throw resp
              }
            })
            .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
              reject(error);
            });
        });
      });
    },
    deleteEventObjectField({ commit, getters, dispatch }, payload) {
      let transmissionGroup = payload.transmissionID

      let fieldsId = getters.getPathIdMap[payload.item.path];

      if (!(fieldsId instanceof Array)) {
        fieldsId = [fieldsId];
      }

      fieldsId.forEach((fieldId) => {
        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/transmission/event_object_field/${fieldId}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };
        return new Promise((resolve, reject) => {
         console.trace(); axiosIns
            .delete("/", config)
            .then((resp) => {
              if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
                dispatch("removePathIdMap", payload.item.path);
                resolve(resp.data.data);
              } else {
                throw resp
              }
            })
            .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
              reject(error);
            });
        });
      });
    },
    deleteAllObjectFields({ getters, dispatch }, payload) {
      let transmissionGroup = payload.transmissionID
      let eventId = getters.getCurrentEvent.id;

      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/event/${eventId}/fields`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              dispatch("removePathIdMap", null);
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    detectObjectFieldsFromJSON({ getters }, payload) {
      let transmissionGroup = payload.transmissionID
      let event = getters.getCurrentEvent;

      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/event/${event.id}/object_field/detect/json`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: payload.jsonObject,
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    resolveEnumType(_, payload) {
      // enum_data_type_id = {
      //   1 : "string",
      //   2 : "int",
      //   3 : "float",
      //   4 : "object",
      //   5 : "boolean",
      //   6 : "JSON list",
      //   7 : "string list",
      //   8 : "int list",
      //   9 : "float list",
      //  10 : "boolean list",
      // }

      // verificar array de objeto = 6
      // verificar array = 7
      // verificar objeto = 4
      // senao = 1

      switch (true) {
        case payload instanceof Array:
          {
            if (payload[0] instanceof Object) {
              return 6;
            }
            return 7;
          }
          break;
        case payload instanceof Object:
          {
            return 4;
          }
          break;
        default: {
          return 1;
        }
      }
    },
  },
};
