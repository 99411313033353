import {axiosCall, apiCall} from '@/store/AxiosCaller.js'


export default {
  namespaced: true,
  state: {
    certificates: undefined,
  },
  getters: {
    getCertificates: (state) => {
      return state.certificates
    },
  },
  mutations: {
    SET_CERTIFICATES(state, payload){
      state.certificates = payload
    },
    REMOVE_CERTIFICATE(state, id){
      let idx = state.certificates.findIndex(el => el.id == id)
      if (idx > -1){
        state.certificates.splice(idx, 1) 
      }
      
    },
    ADD_CERTIFICATE(state, payload){
      if (Array.isArray(state.certificates)){
        state.certificates.push(payload)
      }
    }
  },
  actions: {
    fetchCertificates({commit}, payload){
      let url =  `/certificate/${payload.orgId}/all`
 
      let r = apiCall({
        method: 'get',
        url: url,
      })
      r.then((resp)=>{
        commit('SET_CERTIFICATES', resp.data)
        return resp
      })
      return r
    },
    deleteCertificate({commit}, payload){
      let url =  `/certificate/delete/${payload.certificateId}`
 
      let r = apiCall({
        method: 'delete',
        url: url,
      })
      r.then((resp)=>{
        commit('REMOVE_CERTIFICATE', payload.certificateId)
      })
      return r
    },
    createCertificate({commit}, payload){
      let url =  `/certificate/store`
 
      let r = apiCall({
        method: 'post',
        url: url,
        payload: payload.data
      })
      r.then((resp)=>{
        commit('ADD_CERTIFICATE', resp.data);
      })
      return r
    },
  }
};