export default [
	{
		path: '/transmission/:transmissionID/internal-storage',
		name: 'internal-storage',
		component: () => import('@/views/pages/internal_storage/InternalStorage.vue'),
    meta: {
      title: "FiqOn - Scripts",
      hasTable: true,
      pageTitle: "Scripts",
      breadcrumb: [
        {
          text: 'Transmission Dashboard',
          to: { name: 'transmission-dashboard' }
        },
        {
          text: "Scripts",
          active: true,
        },
      ],
      loadTransmissionGroup: true,
      showTerminal: false,
    },
	},
]