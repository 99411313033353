export default [
    {
      path: '/transmission/:transmissionID/scripts/:open?',
      name: 'scripts',
      component: () =>
          import ('@/views/pages/compiled/List.vue'),
      meta: {
        title: "FiqOn - Scripts",
        hasTable: true,
        pageTitle: "Scripts",
        breadcrumb: [
          {
            text: 'Transmission Dashboard',
            to: { name: 'transmission-dashboard' }
          },
          {
            text: "Scripts",
            active: true,
          },
        ],
        loadTransmissionGroup: true,
			  showTerminal: false,
      },
    },
  ]