import Vue from "vue";
import axiosIns from "@/libs/axios";
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall } from "@/store/AxiosCaller";

export default {
  namespaced: true,
  state: {
    webhook_groups_by_service: {},
  },
  getters: {
    getGroupWebhookByServiceID: (state) => (serviceID) => {
      if (!state.webhook_groups_by_service[serviceID]) return

      return state.webhook_groups_by_service[serviceID]
    },
    getGroupWebhook: (state) => (serviceID, groupID, requestID) => {
      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      if (!state.webhook_groups_by_service[serviceID][groupIdx]) return
      if (!state.webhook_groups_by_service[serviceID][groupIdx].requests) return

      const reqIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      return state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx]
    },
    getWebhookItems: (state) => (payload) => {
      const { requestID, groupID, serviceID, type, direction } = payload
      
      if (!(requestID && groupID && serviceID && type && direction)) return
      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id == groupID)

      if (!state.webhook_groups_by_service[serviceID][groupIdx]) return
      if (!state.webhook_groups_by_service[serviceID][groupIdx].requests) return

      const reqIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id == requestID)

      if (!state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx][type]) return
      
      if (type == 'body') {
        return state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx][type][direction].fields
      }
      return state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx][type][direction] || new Array()
    }
  },
  mutations: {
    PUT_WEBHOOK_GROUP_BY_SERVICE(state, payload) {
      const { serviceID, data } = payload;

      Vue.set(state.webhook_groups_by_service, serviceID, data)
    },
    INSERT_GROUP_WEBHOOK(state, payload) {
      const { serviceID, groupID, data } = payload;

      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      if (!state.webhook_groups_by_service[serviceID][groupIdx].requests) state.webhook_groups_by_service[serviceID][groupIdx].requests = []

      state.webhook_groups_by_service[serviceID][groupIdx].requests.push(data)
    },
    PATCH_GROUP_WEBHOOK_PARAM(state, payload) {
      const { serviceID, requestID, groupID, new_value, field } = payload;

      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      if (!state.webhook_groups_by_service[serviceID][groupIdx].requests) return

      const reqIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      if (!state.webhook_groups_by_service[serviceID][groupIdx].requests && !state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx]) return
      
      Vue.set(state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx], field, new_value)
    },
    DELETE_INTEGRATOR_SERVICE_WEBHOOK(state, payload) {
      const { serviceID, groupID, requestID } = payload;

      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)
      if (!state.webhook_groups_by_service[serviceID][groupIdx]) return

      const reqIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      state.webhook_groups_by_service[serviceID][groupIdx].requests.splice(reqIdx, 1)
    },
    INSERT_SERVICE_GROUP(state, payload) {
      const { serviceID, data } = payload;

      if (!state.webhook_groups_by_service[serviceID]) return
      
      Vue.set(data, 'requests', new Array())

      state.webhook_groups_by_service[serviceID].push(data)
    },
    DELETE_SERVICE_GROUP(state, payload) {
      const { serviceID, groupID } = payload;

      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      state.webhook_groups_by_service[serviceID].splice(groupIdx, 1)
    },
    PATCH_GROUP_PARAM(state, payload) {
      const { serviceID, groupID, new_value, field } = payload;

      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      if (!state.webhook_groups_by_service[serviceID][groupIdx]) return

      Vue.set(state.webhook_groups_by_service[serviceID][groupIdx], field, new_value)
    },
    INSERT_WEBHOOK_ITEM(state, payload) {
      const { serviceID, groupID, requestID, direction, type, data } = payload

      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx][type][direction].push(data)
    },
    DELETE_WEBHOOK_ITEM(state, payload) {
      const { serviceID, groupID, requestID, requestItemID, direction, type,  } = payload

      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      const reqItemIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx][type][direction].findIndex(el => el.id === requestItemID)

      state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx][type][direction].splice(reqItemIdx, 1)
    },
    PATCH_WEBHOOK_ITEM_FIELD(state, payload) {
      const { serviceID, groupID, requestID, direction, type, requestItemID, new_value, field } = payload

      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      const reqItemIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx][type][direction].findIndex(el => el.id === requestItemID)

      Vue.set(state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx][type][direction][reqItemIdx], field, new_value)
    },
    INSERT_WEBHOOK_BODY_ITEM(state, payload) {
      const { serviceID, groupID, requestID, direction, type, data } = payload

      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction].fields.push(data)
    },
    DELETE_WEBHOOK_BODY_ITEM(state, payload) {
      const { serviceID, groupID, requestID, bodyItemID, direction, type } = payload

      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      const bodyItemIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction].fields.findIndex(el => el.id === bodyItemID)

      state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction].fields.splice(bodyItemIdx, 1)
    },
    PATCH_WEBHOOK_BODY_ITEM_FIELD(state, payload) {
      const { serviceID, groupID, requestID, direction, bodyItemID, new_value, field } = payload

      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      const bodyItemIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction].fields.findIndex(el => el.id === bodyItemID)

      Vue.set(state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction].fields[bodyItemIdx], field, new_value)
    },
    PATCH_WEBHOOK_BODY_TYPE(state, payload) {
      const { serviceID, groupID, requestID, direction, new_value, field } = payload

      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      Vue.set(state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction], 'type', new_value)
      if (new_value.has_schema && !state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction].schema) {
        Vue.set(state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction], 'schema', '')
      }
      if (new_value.has_fields && !state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction].fields) {
        Vue.set(state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction], 'fields', [])
      }
    },
    PATCH_WEBHOOK_BODY(state, payload) {
      const { serviceID, groupID, requestID, direction, new_value, field } = payload


      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)
      if (field == 'schema') {
        Vue.set(state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction], field, new_value)
      } else {
        state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction][field].push(new_value)
      }
    },
    PATCH_WEBHOOK_REQUEST_FIELD(state, payload) {
      const { serviceID, groupID, requestID, new_value, field } = payload

      if (!state.webhook_groups_by_service[serviceID]) return

      const groupIdx = state.webhook_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.webhook_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      Vue.set(state.webhook_groups_by_service[serviceID][groupIdx].requests[reqIdx], field, new_value)
    }
  },
  actions: {
    getIntegratorGroupWebhookByServiceID({ commit, state }, serviceID) {
      if (state.webhook_groups_by_service[serviceID]) {
        return
      }
      return axiosCall({
        url: `/${serviceID}/module/integrator_service_group/view/webhooks`,
        method: 'get',
      })
      .then((resp)=>{
        commit('PUT_WEBHOOK_GROUP_BY_SERVICE', { serviceID: serviceID, data: resp })
        return resp
      })
    },
    createWebhook({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { serviceID, groupID, data } = payload

        let config = {
          headers: {
            "x-api-url": `/${serviceID}/module/integrator_service_webhook`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };
        let postData = {
          data: {
            integrator_service_group  : parseInt(groupID),
            enum_metodo               : data.enum_metodo ? data.enum_metodo.id : 1,
            name                      : data.name || 'New Request',
            description               : data.description || '',
            path                      : data.path || '',
            docs_uri                  : data.docs_uri || '',
            enum_body_type            : data.enum_body_type ? data.enum_body_type.id : 1,
            body_schema               : data.body_schema || '',
          }
        };

       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              let data = resp.data.data
              if (!data.headers) {
                Vue.set(data, 'headers', { received: new Array() })
              }
              if (!data.headers) {
                Vue.set(data, 'queries', { received: new Array() })
              }
              commit('INSERT_GROUP_WEBHOOK', { groupID, serviceID, data })
              
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      })
    },
    patchWebhook({ commit }, payload) {
      return new Promise((resolve, reject) => {

        const { field, serviceID, requestID, groupID, new_value } = payload

        let postData = {
          data: {
            new_value,
          },
        };
        let config = {
          headers: {
            "x-api-url": `/${serviceID}/module/integrator_service_webhook/${requestID}/${field}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              
              commit('PATCH_GROUP_WEBHOOK_PARAM', { serviceID, requestID, groupID, new_value: resp.data.data[field], field })
              resolve()
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            console.log(error)
            reject(error.response.data);
          });
      })
    },
    deleteWebhook({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { serviceID, requestID, groupID } = payload;

        let config = {
          headers: {
            "x-api-url": `/${serviceID}/module/integrator_service_webhook/${requestID}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              commit('DELETE_INTEGRATOR_SERVICE_WEBHOOK', { serviceID, requestID, groupID })
              resolve();
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    addGroup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { serviceID, label } = payload;

        let config = {
          headers: {
            "x-api-url": `/${serviceID}/module/integrator_service_group`,
            session: `Session ${localStorage.getItem("sessionKey")}`
          }
        }

        let postData = {
          data: {
            label
          }
        }

       console.trace(); axiosIns
          .post("/", postData, config).then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              
              commit('INSERT_SERVICE_GROUP', { serviceID, data: resp.data.data })
              resolve()
            } else {
              throw resp
            }
          }).catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error)
          })
      })
    },
    deleteGroup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { serviceID, groupID } = payload;

        let config = {
          headers: {
            "x-api-url": `/${serviceID}/module/integrator_service_group/${groupID}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              commit('DELETE_SERVICE_GROUP', { serviceID, groupID })
              resolve();
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    patchGroup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { field, serviceID, groupID, new_value } = payload

        let postData = {
          data: {
            new_value,
          },
        };
        let config = {
          headers: {
            "x-api-url": `/${serviceID}/module/integrator_service_group/${groupID}/${field}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              commit('PATCH_GROUP_PARAM', { serviceID, groupID, new_value, field })
              resolve()
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            console.log(error)
            reject(error.response.data);
          });
      })
    },
    addWebhookItem({ commit }, payload) {
      console.log("wwaaaaa")
      console.log(JSON.stringify(payload))

      return new Promise((resolve, reject) => {
        const { 
          serviceID,
          requestID,
          groupID,
          required,
          key,
          field_type,
          data_type,
          value,
          note,
          direction,
          type
        } = payload

        let data = {
          integrator_service_webhook: requestID,
          required: required,
          field_key: key,
          enum_field_type: field_type.id,
          enum_data_type: data_type.id,
          example_value: value,
          note: note
        }

        let config = {
          headers: {
            "x-api-url": `/${serviceID}/module/integrator_service_webhook_field`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };
        
       console.trace(); axiosIns
          .post("/", { data }, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              if (type != 'body') {
                commit('INSERT_WEBHOOK_ITEM', { serviceID, groupID, requestID, direction, type, data: resp.data.data })
              } else {
                commit('INSERT_WEBHOOK_BODY_ITEM', { serviceID, groupID, requestID, direction, type, data: resp.data.data })
              }
              
              resolve(resp.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    deleteWebhookItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const {
          serviceID,
          requestID,
          groupID,
          direction,
          type,
          requestItemID
        } = payload

        let config = {
          headers: {
            "x-api-url": `/${serviceID}/module/integrator_service_webhook_field/${requestItemID}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              if (type != 'body') {
                commit('DELETE_WEBHOOK_ITEM', { serviceID, groupID, requestID, requestItemID, direction, type })
              } else {
                commit('DELETE_WEBHOOK_BODY_ITEM', { serviceID, groupID, requestID, bodyItemID: requestItemID, direction, type })
              }
              resolve();
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    patchWebhookItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const {
          serviceID,
          groupID,
          requestID,
          direction,
          type,
          requestItemID,
          new_value,
          field
        } = payload

        let config = {
          headers: {
            "x-api-url": `/${serviceID}/module/integrator_service_webhook_field/${requestItemID}/${field}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };
        let postData = {
          data: {
            new_value
          },
        };
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              console.log(resp.data.data)
              if (type == 'body') {
                commit('PATCH_WEBHOOK_BODY_ITEM_FIELD', { serviceID, groupID, requestID, direction, type, bodyItemID: requestItemID, new_value: resp.data.data[field], field })
              } else {
                commit('PATCH_WEBHOOK_ITEM_FIELD', { serviceID, groupID, requestID, direction, type, requestItemID, new_value: resp.data.data[field], field })
              }
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      })
    },
    patchBodyType({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const {
          serviceID,
          groupID,
          requestID,
          direction,
          new_value,
          field
        } = payload

        let config = {
          headers: {
            "x-api-url": `/${serviceID}/module/integrator_service_webhook/${requestID}/${field}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };
        let postData = {
          data: {
            new_value
          },
        };
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              commit('PATCH_WEBHOOK_REQUEST_FIELD',
                {
                  serviceID,
                  groupID,
                  requestID,
                  field: 'body',
                  new_value: resp.data.data.body,
                }
              )
              resolve();
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      })
    },
    patchBody({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const {
          serviceID,
          groupID,
          requestID,
          direction,
          new_value,
          field,
        } = payload

        let config = {
          headers: {
            "x-api-url": `/${serviceID}/module/integrator_service_webhook/${requestID}/body_schema`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };
        let postData = {
          data: {
            new_value
          },
        };
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              commit('PATCH_WEBHOOK_BODY', 
                {
                  serviceID,
                  groupID,
                  requestID,
                  direction,
                  new_value,
                  field,
                }
              )
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      })
    }
  }
};