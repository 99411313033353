import moment from "moment";
import { axiosCall } from "../AxiosCaller";

export default {
  state: {
    currentCompiledScript: undefined,
    transmissionScripts: undefined
  },
  getters: {
    getTransmissionScripts: (state) => {
      return state.transmissionScripts
    }
  },
  mutations: {
    SET_CURRENT_COMPILED_SCRIPT(state, payload) {
        state.currentCompiledScript = payload;
    },
    SET_TRANSMISSION_SCRIPTS(state, payload){
      state.transmissionScripts = payload
    },
    CHANGE_TRANSMISSION_ACTIVE_STATUS(state, payload){
      if (!Array.isArray(state.transmissionScripts)){
        return
      }
      let idx
      idx = state.transmissionScripts.findIndex(el => el.active == true)
      if (idx > -1){
        //Deactivates last Active Script
        state.transmissionScripts[idx].active = false
      }

      idx = state.transmissionScripts.findIndex(el=> el.id == payload.id)

      if (idx > -1){
        //Activates new activated Active Script
        state.transmissionScripts[idx].active = !state.transmissionScripts[idx].active
      }
    },
    REMOVE_SCRIPT_FROM_TRANSMISSION_SCRIPTS(state, itemID){
      if (Array.isArray(state.transmissionScripts)){
        let idx = state.transmissionScripts.findIndex(el => el.id == itemID)
        if (idx > -1){
          state.transmissionScripts.splice(idx, 1)
        }
      }
    },
    REMOVE_MULTIPLE_SCRIPTS(state, ids){
      if (Array.isArray(state.transmissionScripts)){
        ids.forEach((id)=>{
          let idx = state.transmissionScripts.findIndex(el => el.id == id)
          if (idx > -1){
            state.transmissionScripts.splice(idx, 1)
          }
        })
      }

    },
  },
  actions: {
    compileScript({commit, dispatch, state}, {transmissionID, scriptName}) {

      let currentEnv = localStorage.getItem(`currentEnv-${transmissionID}`);
      currentEnv = JSON.parse(currentEnv);
      let data = {
        namespace: scriptName
      }

      return axiosCall(({
        url: `/${transmissionID}/compile/${currentEnv.id}`,
        method: 'post',
        payload: data
      }))
      .then((resp)=>{
        if (Array.isArray(state.transmissionScripts)){
          dispatch('getAllScriptsByTransmission', {transmissionID: transmissionID})
        }
        return resp
      })
    },
    async getAllScriptsByTransmission({ commit, dispatch }, data = null){
      let transmissionGroup = data.transmissionID
      delete data.transmissionID
      
      
      if(data.order == null) {
        data.order = "created_at DESC"
      }

      if(!data.per_page) {
        data.per_page = 500
      }

      let url = await dispatch("resolveFilterUrl", {
        url: `/transmission/${transmissionGroup}/compiled_script`,
        filters: data,
      })

      return axiosCall({
        url: url,
        method: 'get',
        treatResponse: resp => resp
      })
      .then((resp)=>{
        let info = [];
        let total_items = resp.data.data.paginator.total_items;

        resp.data.data.items.map(function(value, key) {
          info.push(value);
          // plansInfo[key].category = value.enum_category.label;
          info[key].created_at = moment(value.created_at).format(
            "LLL"
          );
          info[key].updated_at = moment(value.updated_at).format(
            "LLL"
          );
        });
        info["totalItems"] = total_items;
        commit('SET_TRANSMISSION_SCRIPTS',resp.data.data.items)
        return info;
      })
    },
    changeCompiledActive({ commit, dispatch }, payload) {
      let data = {
        new_value: !payload.active,
      }
      return axiosCall({
        url: `/transmission/${payload.transmissionID}/compiled_script/${payload.item.id}/active`,
        method: 'patch',
        payload: data,
      })
      .then((resp)=>{
        commit('CHANGE_TRANSMISSION_ACTIVE_STATUS',resp)
        return resp;
      })
    },
    deleteCompiledScript({commit}, payload) {
      return axiosCall({
        url: `/transmission/${payload.transmissionID}/compiled_script/${payload.item.id}`,
        method: 'delete',
      })
      .then((resp)=>{
        commit('REMOVE_SCRIPT_FROM_TRANSMISSION_SCRIPTS', payload.item.id)
        return resp;
      })
    },
    deleteMultipleScripts({commit}, payload) {
      return axiosCall({
        url: `/transmission/${payload.transmissionID}/compiled_script/delete_many`,
        method: 'post',
        payload:{
          list: payload.ids
        }
      })
      .then((resp)=>{
        commit('REMOVE_MULTIPLE_SCRIPTS', payload.ids)
        return resp;
      })
    },
    revertCompiledScript({}, payload) {
      let currentEnv = localStorage.getItem(`currentEnv-${payload.transmissionID}`);
      currentEnv = JSON.parse(currentEnv);
    
      ///transmission/{transmission_id}/rollback/compiled_script/{script_id}/env/{env_id}
      return axiosCall({
        //currentEnv.id
        url: `/transmission/${payload.transmissionID}/rollback/compiled_script/${payload.item.script_id}/env/${currentEnv.id}`,
        method: 'post',
        payload: {}
      })
    },
    getCompiledScriptByID(_, payload) {
      return axiosCall({
        url: `/transmission/${payload.transmission_id}/compiled_script/${payload.id}`,
        method: 'get',
      })
    },
    simulateCompiledScript(_, payload) {
      let env = localStorage.getItem(`currentEnv-${payload.transmission_id}`);
      env = JSON.parse(env);
      return axiosCall({
        url: `/transmission/${payload.transmission_id}/compiled_script/simulate/env/${env.id}`,
        method: 'get',
      })
    },
    reRunBulkLogs({},payload){
      return axiosCall({
        url: `/central/${payload.transmissionID}/bulk/retry`,
        method: 'post',
        payload: {tokens: payload.tokens}
      })
    },
    reRunSingleLog({},payload){
      if (payload.body == ""){
        payload.body = {}
      }
      return axiosCall({
        url: `/central/${payload.transmissionID}/retry/${payload.token}`,
        method: 'post',
        payload: {
          data: payload.body
        }
      })
    }
  }
};