import Vue from "vue";
import axiosIns from "@/libs/axios";
import axs from 'axios';
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall } from "@/store/AxiosCaller";

export default {
  namespaced: true,
  state: {
    request_groups_by_service: {},
  },
  getters: {
    getGroupRequestByServiceID: (state) => (serviceID) => {
      if (!state.request_groups_by_service[serviceID]) return

      return state.request_groups_by_service[serviceID]
    },
    getGroupRequest: (state) => (serviceID, groupID, requestID) => {
      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      if (!state.request_groups_by_service[serviceID][groupIdx]) return
      if (!state.request_groups_by_service[serviceID][groupIdx].requests) return

      const reqIdx = state.request_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      return state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx]
    },
    getRequestItems: (state) => (payload) => {
      const { requestID, groupID, serviceID, type, direction } = payload
      
      if (!(requestID && groupID && serviceID && type && direction)) return
      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id == groupID)

      if (!state.request_groups_by_service[serviceID][groupIdx]) return
      if (!state.request_groups_by_service[serviceID][groupIdx].requests) return

      const reqIdx = state.request_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id == requestID)

      if (!state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx][type]) return
      
      if (type == 'body') {
        return state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx][type][direction].fields
      }
      return state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx][type][direction] || new Array()
    }
  },
  mutations: {
    PUT_GROUP_BY_SERVICE(state, payload) {
      const { serviceID, data } = payload;

      Vue.set(state.request_groups_by_service, serviceID, data)
    },
    INSERT_GROUP_REQUEST(state, payload) {
      const { serviceID, groupID, data } = payload;

      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      if (!state.request_groups_by_service[serviceID][groupIdx].requests) state.request_groups_by_service[serviceID][groupIdx].requests = []

      state.request_groups_by_service[serviceID][groupIdx].requests.push(data)
    },
    PATCH_GROUP_REQUEST_PARAM(state, payload) {
      const { serviceID, requestID, groupID, new_value, field } = payload;

      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      if (!state.request_groups_by_service[serviceID][groupIdx].requests) return

      const reqIdx = state.request_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      if (!state.request_groups_by_service[serviceID][groupIdx].requests && !state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx]) return
      
      Vue.set(state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx], field, new_value)
    },
    DELETE_INTEGRATOR_SERVICE_REQUEST(state, payload) {
      const { serviceID, groupID, requestID } = payload;

      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)
      if (!state.request_groups_by_service[serviceID][groupIdx]) return

      const reqIdx = state.request_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      state.request_groups_by_service[serviceID][groupIdx].requests.splice(reqIdx, 1)
    },
    INSERT_SERVICE_GROUP(state, payload) {
      const { serviceID, data } = payload;

      if (!state.request_groups_by_service[serviceID]) return
      
      Vue.set(data, 'requests', new Array())

      state.request_groups_by_service[serviceID].push(data)
    },
    DELETE_SERVICE_GROUP(state, payload) {
      const { serviceID, groupID } = payload;

      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      state.request_groups_by_service[serviceID].splice(groupIdx, 1)
    },
    PATCH_GROUP_PARAM(state, payload) {
      const { serviceID, groupID, new_value, field } = payload;

      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      if (!state.request_groups_by_service[serviceID][groupIdx]) return

      Vue.set(state.request_groups_by_service[serviceID][groupIdx], field, new_value)
    },
    INSERT_REQUEST_ITEM(state, payload) {
      const { serviceID, groupID, requestID, direction, type, data } = payload

      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.request_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx][type][direction].push(data)
    },
    DELETE_REQUEST_ITEM(state, payload) {
      const { serviceID, groupID, requestID, requestItemID, direction, type,  } = payload

      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.request_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      const reqItemIdx = state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx][type][direction].findIndex(el => el.id === requestItemID)

      state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx][type][direction].splice(reqItemIdx, 1)
    },
    PATCH_REQUEST_ITEM_FIELD(state, payload) {
      const { serviceID, groupID, requestID, direction, type, requestItemID, new_value, field } = payload

      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.request_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      const reqItemIdx = state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx][type][direction].findIndex(el => el.id === requestItemID)

      Vue.set(state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx][type][direction][reqItemIdx], field, new_value)
    },
    INSERT_REQUEST_BODY_ITEM(state, payload) {
      const { serviceID, groupID, requestID, direction, type, data } = payload

      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.request_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction].fields.push(data)
    },
    DELETE_REQUEST_BODY_ITEM(state, payload) {
      const { serviceID, groupID, requestID, bodyItemID, direction, type } = payload

      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.request_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      const bodyItemIdx = state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction].fields.findIndex(el => el.id === bodyItemID)

      state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction].fields.splice(bodyItemIdx, 1)
    },
    PATCH_REQUEST_BODY_ITEM_FIELD(state, payload) {
      const { serviceID, groupID, requestID, direction, bodyItemID, new_value, field } = payload

      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.request_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      const bodyItemIdx = state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction].fields.findIndex(el => el.id === bodyItemID)

      Vue.set(state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction].fields[bodyItemIdx], field, new_value)
    },
    PATCH_REQUEST_BODY_TYPE(state, payload) {
      const { serviceID, groupID, requestID, direction, new_value, field } = payload

      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.request_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      Vue.set(state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction], 'type', new_value)
      if (new_value.has_schema && !state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction].schema) {
        Vue.set(state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction], 'schema', '')
      }
      if (new_value.has_fields && !state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction].fields) {
        Vue.set(state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction], 'fields', [])
      }
    },
    PATCH_REQUEST_BODY(state, payload) {
      const { serviceID, groupID, requestID, direction, new_value, field } = payload


      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.request_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)
      if (field == 'schema') {
        Vue.set(state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction], field, new_value)
      } else {
        state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx].body[direction][field].push(new_value)
      }
    },
    PATCH_REQUEST_FIELD(state, payload) {
      const { serviceID, groupID, requestID, new_value, field } = payload

      if (!state.request_groups_by_service[serviceID]) return

      const groupIdx = state.request_groups_by_service[serviceID].findIndex(el => el.id === groupID)

      const reqIdx = state.request_groups_by_service[serviceID][groupIdx].requests.findIndex(el => el.id === requestID)

      Vue.set(state.request_groups_by_service[serviceID][groupIdx].requests[reqIdx], field, new_value)
    }
  },
  actions: {
    getIntegratorGroupRequestByServiceID({ commit, state }, serviceID) {
      return new axiosCall({
        url: `/${serviceID}/module/integrator_service_group/view/requests`,
        method: "get",
      })
      .then((resp)=>{
        commit('PUT_GROUP_BY_SERVICE', { serviceID: serviceID, data: resp })
        return resp
      })
    },
    createRequestComplete({commit}, payload){
      
      const { serviceID, groupID, data } = payload

      console.log('%c'+JSON.stringify(data), 'color: limegreen');
      
      const payloadData = {
        integrator_service_group  : parseInt(groupID),
        enum_metodo               : data.enum_metodo?.id || 1,
        name                      : data.name || 'New Request',
        description               : data.description || '',
        endpoint                  : data.endpoint || '',
        docs_uri                  : data.docs_uri || '',
        enum_body_type            : data.enum_body_type ? data.enum_body_type.id : 1,
        body_schema               : data.body_schema || '',
        enum_return_body_type     : data.enum_return_body_type ? data.enum_return_body_type.id : 1,
        return_body_schema        : data.return_body_schema || '',
        queries                   : data.queries,
        headers                   : data.headers,
      }

      return new axiosCall({
        url: `/${serviceID}/module/integrator_service_request/complete`,
        method: 'post',
        payload: payloadData,
        treatResponse: resp => resp
      })
      .then((resp)=>{
        let data = resp.data.data
        if (!data.headers) {
          Vue.set(data, 'headers', { sent: new Array(), received: new Array() })
        }
        if (!data.headers) {
          Vue.set(data, 'queries', { sent: new Array() })
        }
        commit('INSERT_GROUP_REQUEST', { groupID, serviceID, data })
        
        return(resp.data.data);
      })
    },
    createRequest({ commit }, payload) {
      const { serviceID, groupID, data } = payload
      const payloadData = {
        integrator_service_group  : parseInt(groupID),
        enum_metodo               : data.enum_metodo?.id || 1,
        name                      : data.name || 'New Request',
        description               : data.description || '',
        endpoint                  : data.endpoint || '',
        docs_uri                  : data.docs_uri || '',
        enum_body_type            : data.enum_body_type ? data.enum_body_type.id : 2,
        body_schema               : data.body_schema || '',
        enum_return_body_type     : data.enum_return_body_type ? data.enum_return_body_type.id : 1,
        return_body_schema        : data.return_body_schema || '',
      }

      return new axiosCall({
        url: `/${serviceID}/module/integrator_service_request`,
        method: 'post',
        payload: payloadData,
        treatResponse: resp => resp
      })
      .then((resp)=>{
        let data = resp.data.data
        if (!data.headers) {
          Vue.set(data, 'headers', { sent: new Array(), received: new Array() })
        }
        if (!data.headers) {
          Vue.set(data, 'queries', { sent: new Array() })
        }
        commit('INSERT_GROUP_REQUEST', { groupID, serviceID, data })
        
        return(resp.data.data);
      })
    },
    patchRequest({ commit }, payload) {
      
      const { field, serviceID, requestID, groupID, new_value } = payload
      const postData = {
        new_value,
      }
      return new axiosCall({
        url: `/${serviceID}/module/integrator_service_request/${requestID}/${field}`,
        method: 'patch',
        payload: postData,
      })
      .then((resp)=>{     
        commit('PATCH_GROUP_REQUEST_PARAM', { serviceID, requestID, groupID, new_value: resp[field], field })
        return
      })
    },
    deleteRequest({ commit }, payload) {
      const { serviceID, requestID, groupID } = payload;

      return axiosCall({
        url: `/${serviceID}/module/integrator_service_request/${requestID}`,
        method: 'delete',
      })
      .then((resp)=>{
        commit('DELETE_INTEGRATOR_SERVICE_REQUEST', { serviceID, requestID, groupID })
        return 
      })
    },
    addGroup({ commit }, payload) {
      const { serviceID, label } = payload;
      const postData = {
        label
      }
      return new axiosCall({
        url: `/${serviceID}/module/integrator_service_group`,
        method: 'post',
        payload: postData
      })
      .then((resp)=>{
        commit('INSERT_SERVICE_GROUP', { serviceID, data: resp })
        return 
      })

    },
    deleteGroup({ commit }, payload) {
      const { serviceID, groupID } = payload;

      return new axiosCall({
        url: `/${serviceID}/module/integrator_service_group/${groupID}`,
        method: 'delete'
      })
      .then((resp)=>{
        commit('DELETE_SERVICE_GROUP', { serviceID, groupID })
        return
      })
    },
    patchGroup({ commit }, payload) {
      const { field, serviceID, groupID, new_value } = payload
      const postData = {
        new_value,
      }
      return axiosCall({
        url: `/${serviceID}/module/integrator_service_group/${groupID}/${field}`,
        method: 'patch',
        payload: postData
      })
      .then((resp)=>{
        commit('PATCH_GROUP_PARAM', { serviceID, groupID, new_value, field })
        return
      })
    },
    addRequestItem({ commit }, payload) {
      const { 
        serviceID,
        requestID,
        groupID,
        required,
        key,
        field_type,
        data_type,
        value,
        note,
        direction,
        type
      } = payload
      console.log('%c'+JSON.stringify(payload), 'color: limegreen');
      

      let payloadData = {
        integrator_service_request: requestID,
        required: required,
        field_key: key,
        enum_field_type: field_type.id,
        enum_data_type: data_type.id,
        example_value: value,
        note: note
      }
      return axiosCall({
        url: `/${serviceID}/module/integrator_service_request_field`,
        method: "post",
        payload: payloadData,
        treatResponse: resp => resp
      })
      .then((resp)=>{
        if (type != 'body') {
          commit('INSERT_REQUEST_ITEM', { serviceID, groupID, requestID, direction, type, data: resp.data.data })
        } else {
          commit('INSERT_REQUEST_BODY_ITEM', { serviceID, groupID, requestID, direction, type, data: resp.data.data })
        }
        
        return resp.data;
      })
    },
    deleteRequestItem({ commit }, payload) {
      const {
        serviceID,
        requestID,
        groupID,
        direction,
        type,
        requestItemID
      } = payload

      return new axiosCall({
        url: `/${serviceID}/module/integrator_service_request_field/${requestItemID}`,
        method: 'delete',
      })
      .then((resp)=>{
        if (type != 'body') {
          commit('DELETE_REQUEST_ITEM', { serviceID, groupID, requestID, requestItemID, direction, type })
        } else {
          commit('DELETE_REQUEST_BODY_ITEM', { serviceID, groupID, requestID, bodyItemID: requestItemID, direction, type })
        }
        return 
      })
    },
    patchRequestItem({ commit }, payload) {
      const {
        serviceID,
        groupID,
        requestID,
        direction,
        type,
        requestItemID,
        new_value,
        field
      } = payload
      const postData = {
        new_value
      }

      return axiosCall({
        url: `/${serviceID}/module/integrator_service_request_field/${requestItemID}/${field}`,
        method: 'patch',
        payload: postData,
        treatResponse: resp => resp
      })
      .then((resp)=>{
        if (type == 'body') {
          commit('PATCH_REQUEST_BODY_ITEM_FIELD', { serviceID, groupID, requestID, direction, type, bodyItemID: requestItemID, new_value: resp.data.data[field], field })
        } else {
          commit('PATCH_REQUEST_ITEM_FIELD', { serviceID, groupID, requestID, direction, type, requestItemID, new_value: resp.data.data[field], field })
        }
        resolve(resp);
      })
    },
    patchBodyType({ commit }, payload) {
      const {
        serviceID,
        groupID,
        requestID,
        direction,
        new_value,
        field
      } = payload
      const postData = {
        new_value 
      }
      return new axiosCall({
        url: `/${serviceID}/module/integrator_service_request/${requestID}/${field}`,
        method: 'patch',
        payload: postData
      })
      .then((resp)=>{
        commit('PATCH_REQUEST_FIELD',{ serviceID, groupID, requestID, field: 'body', new_value: resp.body,}
      )
      return;
      })
    },
    patchBody({ commit }, payload) {
      const {
        serviceID,
        groupID,
        requestID,
        direction,
        new_value,
        field,
        request_field
      } = payload
      const postData = {
          new_value
      }

      return axiosCall({
        url: `/${serviceID}/module/integrator_service_request/${requestID}/${request_field}`,
        method: 'patch',
        payload: postData,
        treatResponse: resp => resp
      })
      .then((resp)=>{
        commit('PATCH_REQUEST_BODY', {serviceID, groupID, requestID, direction, new_value, field,})
        return resp
      })
    },
    sendExternalRequest( _, payload) {
      return new Promise((resolve, reject) => {
        const { method, endpoint, body_type, body, headers } = payload
        
        let data = null

        switch (body_type.id) {
          case 2: {
            data = JSON.stringify(body)
          } break;
          case 3: {
            data = `${body}`
          } break;
          case 4: {
            data = new FormData()

            body.forEach((el) => {
              data.append(el.field_key, key.example_value)
            })
            console.log(data)
          } break;
          case 5: {
            const qs = require('qs')

            let urlencoded_data = {}

            body.forEach((el) => {
              urlencoded_data[el.key] = el.value
            })

            data = qs.stringify(urlencoded_data)
          } break;
          case 6: {
            data = `${body}`
          } break;
        }

        var config = {
          method,
          url: endpoint,
          headers: data ? { ...headers, 'Content-Type': body_type.tag } : headers,
          data: data || undefined
        };
      
        axs(config)
        .then((response) => {
          resolve(JSON.stringify(response));
        })
        .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
          reject(error);
        });
      })
    }
  }
};