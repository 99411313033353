import axiosIns from "@/libs/axios";
import EventBus from "@/custom/eventBus";
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall } from "../AxiosCaller";

export default {
  state: {
    value: 'my value',
    environments: undefined,
  },
  getters: {
    value: state => {
      return state.value;
    },
    getEnvironments(state){
      return state.environments
    }
  },
  mutations: {
    updateValue(state, payload) {
      state.value = payload;
    },
    SET_ENVIRONMENTS_DATA(state, payload){
      state.environments = payload;
    },
    ADD_ENVIRONMENT(state, payload){
      if (Array.isArray(state.environments)){
        state.environments.push(payload)
      }
    },
    REMOVE_ENVIRONMENT(state, payload){
      const idx = state.environments.findIndex((el)=>{
        return el.id == payload.id
      })
      
      if (idx > -1 ){
        state.environments.splice(idx , 1)
      }
    },
    PATCH_ENV_NAME(state,payload){
      const idx = state.environments.findIndex((el)=>{
        return el.id == payload.envId
      })
      if (idx > -1){
        state.environments[idx].name = payload.envName
      }
    }
  },
  actions: {
    async getAllEnvironmentsByTransmissionGroup({ dispatch, commit }, payload = null){

      let data = { 
        page: payload.page || null,
        per_page: payload.per_page || 10,
        order: "id DESC"
      }
      return axiosCall({
        url:  await dispatch('resolveFilterUrl', { url: `/${payload.transmissionID}/transmission/environment`, filters: data }),
        method: 'get'
      }).then((resp)=>{
        let environments = resp

        environments["links"] = resp.__links;
        environments["paginator"] = resp.paginator;
        commit("SET_ENVIRONMENTS_DATA", environments)
        return environments;
      })
    },
    getEnvironmentById({ commit }, payload){

      return axiosCall({
        url: `/${payload.transmissionID}/export_vars/${payload.envID}`,
        method: 'get'
      })
      .then((resp)=>{
        return(JSON.parse(resp.script));
      })
    },
    addEnvironment({commit} , payload) {
      let postData = {};

      return axiosCall({
        url: `/${payload.transmissionID}/transmission/environment`,
        method: 'post',
        payload: postData
      })
      .then((resp)=>{
        // EventBus.$emit('reloadEnvs')
        commit("ADD_ENVIRONMENT", resp)
        return resp;
      })
    },
    importEnvironmentVars({ commit }, payload) {
      let postData = {
        script: JSON.stringify(payload.script)
      };
      return axiosCall({
        url: `/${payload.transmissionID}/import/${payload.environment.id}?w=true`,
        method: 'post',
        payload: postData
      })
      .then((resp)=>{
        return resp
      })
    },
    deleteEnvironment({commit}, payload) {
      return axiosCall({
        url:  `/${payload.transmissionID}/transmission/environment/${payload.envId}`,
        method: 'delete'
      })
      .then((resp)=>{
        commit('REMOVE_ENVIRONMENT', {id: payload.envId})
        return resp
      })
    },
    editEnvironmentName({commit} , payload) {
      let patchData = {
        new_value : payload.envName
      }
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/environment/${payload.envId}/name`,
        method: 'patch',
        payload: patchData
      }).then((resp)=>{
        commit('PATCH_ENV_NAME', payload)
        return resp
      })
    },
    cloneEnvironment({ dispatch}, payload) {
      return new Promise((resolve, reject) => {
        dispatch('importEnvironmentVars', { environment: payload.env, script: payload.script, transmissionID: payload.transmissionID }).then((response) => {
          if(resp.status == 200 && !resp.data.error) {
            resolve(response)
          } else {
            throw resp
          }
        }).catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
          reject(error)
        })
      })
    },
    setEnvironment({commit} , payload) {
      let data = {
        new_value: payload.value
      }
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/environment/${payload.envId}/value`,
        method: 'patch',
        payload: data
      }).then((resp)=>{
        return resp
      })
    },
    getEnvironmentDecrypted({commit}, payload){
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/environment/${payload.envId}`,
        method: "get",
      })
    }
  }
};