import { t } from '@/@core/libs/i18n/utils';
import {apiCall, axiosCall} from '@/store/AxiosCaller.js'
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    
    connectionsByOrg: undefined,
  },
  getters: {
    getConnections: (state) => {
      return state.connectionsByOrg
    },
    
  },
  mutations: {
    SET_ORG_CONNECTIONS(state, payload){
      state.connectionsByOrg = payload
    },
    ADD_CONNECTION(state, payload){
      if (Array.isArray(state.connectionsByOrg)){
        state.connectionsByOrg.push(payload) 
      }
    },
    PATCH_CONNECTION(state, payload){
      if (Array.isArray(state.connectionsByOrg)){
        let idx = state.connectionsByOrg.findIndex(el=> el.id == payload.id)
        if (idx>-1){          
          state.connectionsByOrg[idx] = payload
        }
      }
    },
    REMOVE_CONNECTION(state, payload){
      if (Array.isArray(state.connectionsByOrg)){
        let idx = state.connectionsByOrg.findIndex(el=> el.id == payload.id)
        state.connectionsByOrg.splice(idx, 1)
      }
    },
    CLEAR_STORED_CONNECTIONS(state, payload){
      state.connectionsByOrg = undefined
    }
  },

  actions: {
    clearStoredConnections({commit}, payload){
      commit('CLEAR_STORED_CONNECTIONS')
      return 
    },
    getOrgConnections({commit}, payload){
      let url =  `/organization/${payload.organization}/connection`
 
      let r = axiosCall({
        method: 'get',
        url: url,
      })
      r.then((resp)=>{
        commit('SET_ORG_CONNECTIONS',resp)
      })
      return r
    },
    getConnectionInfoByID({commit}, payload){
      let url = `/organization/${payload.organization}/connection/${payload.connection}`

      return axiosCall({
        method: 'get',
        url: url,
      })
    },
    createConnection({commit}, payload){
      let url = `/organization/${payload.organization}/connection`

      let r = axiosCall({
        method: 'post',
        payload: payload,
        url: url,
      })
      r.then((resp)=>{
        commit('ADD_CONNECTION', resp)
      })
      return r
    },
    testConnection({commit}, payload){
      return apiCall({
        url: "connections/test/connection",
        method:"post",
        payload: payload        
      })
    //   let url = `${process.env.VUE_APP__ENGINE_URL}`
    //   if (! (url.endsWith('/')) ){
    //     url = url + '/'
    //   } 
    //   url = url + "check/connection"

    //   return axios({
    //     method: 'post',
    //     url: url,
    //     data: payload,
    //   })
    },
    async patchConnectionField({commit, dispatch}, payload){
      let orgID = payload.organization
      let connection = payload.connection

      delete payload.organization
      delete payload.connection

      let data = payload
        
      let key = Object.keys(data)[0]
      let temp = data[key];
      delete data[key]

      data.new_value = temp


      let url =  `/organization/${orgID}/connection/${connection}/${key}`
      let r = axiosCall({
        method: 'patch',
        url: url,
        payload: data
      })
      r.then((resp)=>{
        commit('PATCH_CONNECTION',{...resp, [key]:temp})
      })
      return r
    },
    deleteConnection({commit}, payload){
      let url = `/organization/${payload.organization}/connection/${payload.connection}`

      let r = axiosCall({
        method: 'delete',
        url: url,
        
      })
      r.then(()=>{
        commit('REMOVE_CONNECTION',{id: payload.connection})
      })
      return r
    },
  }
};