//import useJwt from '@/auth/jwt/useJwt'
import store from '@/store';
import Vue from "vue";
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall, apiCall} from "@/store/AxiosCaller";
import router from '@/router';

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = async () => {
  let userLogged = await loadUserFromSession();

  if (!userLogged) {
    console.log("You are not logged");
    return false;
  } else {
    return userLogged;
  }
};

async function loadUserFromSession() {
  if (! localStorage.getItem("sessionKey")){
    return
  }
  
  return apiCall({
    url: "user/token/valid",
    method: 'get'
  })
  .then((resp)=>{
    let userData = resp.data;
    if (userData.token != localStorage.getItem("sessionKey")){
      localStorage.setItem("sessionKey", userData.token)
      window.location.reload(true)
    }
    
  
    store.commit('SET_LOGGED_USER_SESSION', userData)
    
    switch (true) {
      case !userData.email_verified: {
        return { name: "verifiy-email" };
      }
      case !userData.phone_verified: {
        return { name: "verifiy-phone" };
      }
      default: {
        defineAbility(userData.enum_access_level)
        return { name: "home" };
      }
    }
  }).catch((err)=>{
    console.error(err)
    console.error('[API] - TOKEN REQUEST ERROR')
    if (err?.response?.data != null){
      makeToast({
        title: 'Session Expired',
        text: 'please login again',
        variant: "secondary",
        icon: "InfoIcon",
      })
      localStorage.removeItem("sessionKey")
      router.push({name: 'login'})
    } 
  })
}

async function defineAbility(accessLevel) {
  accessLevel.label = accessLevel.name

  if (accessLevel.id == 666) {
    Vue.prototype.$ability.update([
      {
        action: "manage",
        subject: "all",
      },
    ]);
  } 
  else if (accessLevel.label == "Developer") {
    Vue.prototype.$ability.update([
      
      //what we do here is first we allow everything and then revoke certain actions on certain fields
      //that's done through 'action: manage, inverted: true'
      //don't think too hard about it
      
      {
        action: ["manage"],
        subject: "all",
      },
      {
        action: "manage",
        resource: "server",
        subject: "server",
        inverted: true
      },
      {
        action: "manage",
        resource: "plan",
        subject: "plan",
        inverted: true
      },
      {
        action: "manage",
        resource: "organization",
        subject: "organization",
        inverted: true
      },
      {
        action: "read",
        resource: "dashboard",
        subject: "dashboard",
        inverted: true
      },
      {
        action: "manage",
        subject: "integratorCategory",
        inverted: true
      },
    ]);
  } 
  else if (accessLevel.id == 2){
    Vue.prototype.$ability.update([
      {
        action: "read",
        subject: "all",
      },
    ]);
  } else {
    Vue.prototype.$ability.update([
      {
        action: "read",
        subject: "all",
      },
    ]);
  }
}
// export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return { name: "access-control" };
  return { name: "auth-login" };
};
