import axiosIns from "@/libs/axios";
import moment, { localeData } from 'moment'
import Vue from "vue";
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { apiCall, axiosCall } from "../AxiosCaller";
import axios from "axios";
import { getDate } from "@/custom/class/FunctionClasses/HelperFunctions";

export default {
  state: {
    selectedPlan: null,
    selectedBaseServer: null,
    selectedSystemLogServer: null,
    selectedProcessedLogServer: null,
    selectedTransmissionGroup: null,
    currentGroup: null,
    groupAction: null,
    transmissions: {
      orgID: null,
      items: null,
    },
    history:{
      data: undefined,
      page: undefined,
      date: undefined,
      isLoading: false,
      isFinished: false, 
    },
    schedules: {},
    variablesPannelVars: undefined,
  },
  getters: {
    getSelectedPlan: (state) => {
      return state.selectedPlan;
    },
    getSelectedBaseServer: (state) => {
      return state.selectedBaseServer;
    },
    getSelectedSystemLogServer: (state) => {
      return state.selectedSystemLogServer;
    },
    getSelectedProcessedLogServer: (state) => {
      return state.selectedProcessedLogServer;
    },
    getSelectedTransmissionGroup: (state) => {
      return state.selectedTransmissionGroup;
    },
    getCurrentGroup: (state) => {
      return state.currentGroup
    },
    getGroupAction: (state) => {
      return state.groupAction
    },
    getTransmissions: (state) => {
      return state.transmissions
    },
    getVariablesPannelVars: (state) => {
      return state.variablesPannelVars
    },
    getTransmissionHistory: (state) => {
      return state.history
    }
  },
  mutations: {
    SET_SELECTED_PLAN(state, payload) {
      state.selectedPlan = payload;
    },
    SET_SELECTED_BASE_SERVER(state, payload) {
      state.selectedBaseServer = payload;
    },
    SET_SELECTED_SYSTEM_LOG_SERVER(state, payload) {
      state.selectedSystemLogServer = payload;
    },
    SET_SELECTED_PROCESSED_LOG_SERVER(state, payload) {
      state.selectedProcessedLogServer = payload;
    },
    SET_SELECTED_TRANSMISSION_GROUP(state, payload) {
      state.selectedTransmissionGroup = payload;
    },
    SET_CURRENT_GROUP(state, payload) {
      state.currentGroup = payload
    },
    SET_GROUP_ACTION(state, payload) {
      state.groupAction = payload
    },
    SET_TRANSMISSIONS(state, payload) {
      state.transmissions.items = payload.items
      state.transmissions.orgID = payload.orgID
    },
    SET_VARIABLES_PANNEL(state, payload) {
      state.variablesPannelVars = payload
    },
    SET_TRANSMISSION_HISTORY(state, payload){
      if (payload.meta.current_page >= payload.meta.last_page){
        state.history.isFinished = true
      }
      state.history.isLoading = false;
      if (state.history.page == null){
        state.history.page = 1
      }
      state.history.page = state.history.page + 1

      //the structure is an Array of days, where each day is an Array of logs
      let mappedHistory = structuredClone(state.history.data) || [ ]
      
      payload.data.forEach((log)=>{
        
        let lastDay = mappedHistory.slice(-1)[0]// => Array of logs

        const lDate = getDate(log.datetime)
        let lastLog =  lastDay?.slice(-1)[0]

        const lastLogDate = getDate(lastLog?.datetime)
        
        if (lastLogDate != lDate){
          mappedHistory.push( [log] )
        } else {
          mappedHistory[mappedHistory.length - 1].push(log)
        }
      })
      state.history.data = mappedHistory      
    },
    ADD_TRANSMISSION(state, payload) {
      state.transmissions.items.unshift(payload)
    },
    UPDATE_TRANSMISSION(state, payload) {
      if (state.selectedTransmissionGroup){
        state.selectedTransmissionGroup = payload
      }

      if(Array.isArray(state.transmissions?.items) ){
        let items = state.transmissions.items
        items.forEach((value, index) => {
          if(value.id == payload.id) {
            Vue.prototype.$set(items, [index], payload)
          }
        })
      } 
    },
    DELETE_TRANSMISSION(state, id) {
      let items = state.transmissions.items
      items.forEach((value, index) => {
        if(value.id == id) {
          items.splice(index, 1)
        }
      })
    }
  },
  actions: {
    setSelectedPlan({ commit }, payload) {
      commit("SET_SELECTED_PLAN", payload);
    },
    setSelectedBaseServer({ commit }, payload) {
      commit("SET_SELECTED_BASE_SERVER", payload);
    },
    setSelectedSystemLogServer({ commit }, payload) {
      commit("SET_SELECTED_SYSTEM_LOG_SERVER", payload);
    },
    setSelectedProcessedLogServer({ commit }, payload) {
      commit("SET_SELECTED_PROCESSED_LOG_SERVER", payload);
    },
    setSelectedTransmissionGroup({ commit }, payload) {
      commit('SET_SELECTED_TRANSMISSION_GROUP', payload);
    },
    setGroupAction({ commit }, payload) {
      commit("SET_GROUP_ACTION", payload)
    },
    createTransmissionGroup({ commit, getters, dispatch }, data) {
      let postData = {
        name: data.name,
        description: data.description ? data.description : "",
        organization: parseInt(
          localStorage.getItem('selectedOrganization')
        ),
        mysql_db: getters.getSelectedBaseServer.id,
        processed_log: getters.getSelectedProcessedLogServer.id,
        system_log: getters.getSelectedSystemLogServer.id,
      };

      return axiosCall({
        url: "/central/central_transmission",
        method: "post",
        payload: postData,
        treatResponse: resp => resp,
      })
      .then((resp) => {
        if(getters.getTransmissions.items) {
          commit('ADD_TRANSMISSION', resp.data.data)
        }
        
        dispatch("setPlanSubscription", {
          data: resp.data.data,
          plan: getters.getSelectedPlan,
        })
        .then((resp) => {
          commit("SET_LOADING_STATE", false);
          commit("SET_SELECTED_PLAN", null);
          commit("SET_SELECTED_BASE_SERVER", null);
          commit("SET_SELECTED_SYSTEM_LOG_SERVER", null);
          commit("SET_SELECTED_PROCESSED_LOG_SERVER", null);
          return resp
        })
        .catch((error) => {
          throw(error);
        });
        
        return resp;
      })
    },
    getTransmissionGroupById({ commit , state }, data) {
      let transmissionID = data.transmissionID;
      
      if(state.selectedTransmissionGroup?.id == transmissionID){
        return state.selectedTransmissionGroup
      }
      
      return apiCall({
        url: `transmission/info/${transmissionID}`,
        method: 'get'
      }).then((resp)=>{
        commit('SET_SELECTED_TRANSMISSION_GROUP', resp.data)
        commit('SET_CURRENT_GROUP', resp.data)
        
        return(resp.data);
      })
    },
    async getTransmissionByOrganization({ dispatch, commit }, payload ) {
      let orgId = localStorage.getItem('selectedOrganization');
      if (payload.organization){
        orgId = String(payload.organization)
      }
      let data = orgId ? { value: orgId, ...payload.filter } : resolve([]);

      let url = await dispatch("resolveUrl", {
        // url: `/central/central_transmission/by/organization?per_page=50`,
        url: `/central/central_transmission/by/organization`,
        params: data,
      })

      return axiosCall({
        url: url,
        method: 'get',
      }).then((resp)=>{
        let items = resp.items;
        items.map((value, key) => {
          items[key].created_at = moment(value.created_at).format('LL')
          items[key].updated_at = moment(value.updated_at).format('LL')
        })
        commit('SET_TRANSMISSIONS', { items, orgID: orgId });
        return(items);
      })
    },
    patchNotificationField({commit}, payload){
      let field = payload.field
      const pathMap = {
        user: "user",
        email: "email",
        webhook: "webhook",
        critical_error: "critical/error",
        non_critical_error: "non-critical/error",
      }
      
      if (field == 'user'){
        payload.newValue = payload.newValue.map(el=> el.id)
      }
      
      return apiCall({
        url: `transmission/${payload.transmissionID}/notification/register/${pathMap[field]}`,
        method: 'patch', 
        payload:{
          new_value: payload.newValue
        }
      })
      .then((resp)=>{
        return resp
      })
    },
    patchTransmission({commit}, payload){
      let field = payload.field
      
      return axiosCall({
        url: `/central/central_transmission/${payload.transmissionID}/${field}`,
        method: 'patch', 
        payload:{
          new_value: payload.newValue
        }
      })
      .then((resp)=>{
        commit('UPDATE_TRANSMISSION', resp)
        return resp
      })
    }, 
    changeMaxMiddlewares({commit}, payload){

      return axiosCall({
        url: `/central/central_transmission/${payload.transmissionID}/max_middleware`,
        method: 'patch',
        payload: {new_value: payload.newValue}
      })
      
    },
    fetchTransmissionHistory({commit, state}, payload){
      let page = state.history.page
      
      if (state.history.date == null){
        state.history.date = payload.date
      }

      if (state.history.isFinished){
        return
      }
      else if (state.history.date != payload.date){
        state.history.data = undefined
        state.history.date = payload.date
        state.history.page = 1
        state.history.isFinished = false
        page = 1
      } else {
        if (page == null){
          page = 1
        }
      }
      state.history.isLoading = true

      let date = payload.date
      return apiCall({
        url: `/${payload.transmissionID}/logs`,
        method: 'get',
        queries:{
          date: date,
          per_page: 30,
          page: page,
        }
      }).then((resp)=>{
        commit('SET_TRANSMISSION_HISTORY',resp)
        return resp
      }).catch((err)=>{
        console.error(err)
      })
    },
    getRequestLogs(_ , payload) {
      return new Promise((resolve, reject) => {
        let transmissionGroup = payload.transmissionID

        if (!transmissionGroup) {
          reject({ "message": "common.error.transmission_not_selected" })
        }

        let query_id = "";
        if (payload.previous_id) {
          query_id = `?id=${payload.previous_id}`;
        }

        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/transmission/log/all${query_id}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            // commit('SET_IS_BTABLE_BUSY', false)
            reject(error);
          });
      });
    },
    getRequestOccurrencesLogs(_ , payload) {
      return new Promise((resolve, reject) => {
        let transmissionGroup = payload.transmissionID

        if (!transmissionGroup) {
          reject({ "message": "common.error.transmission_not_selected" })
        }

        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/transmission/log/token/${payload.token}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            // commit('SET_IS_BTABLE_BUSY', false)
            reject(error);
          });
      });
    },
    deleteTransmissionById({ getters, commit }, id) {
      return axiosCall({
        url: `/central/central_transmission/${id}`,
        method: 'delete',
      }).then((resp)=>{
        if(getters.getTransmissions.items) {
          commit('DELETE_TRANSMISSION', id)
          return resp
        }
      })
    },
    exportWebhookByIDs(_, payload) {
      return new Promise((resolve, reject) => {
        let transmissionGroup = payload.transmissionID

        if (!transmissionGroup) {
          reject({ "message": "common.error.transmission_not_selected" })
        }
        
        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/export_webhooks/by/id`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

        const postData = {
          "data": {
            "webhooks": payload.id_list
          }
        }

       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      })
    },
    importScript(_, payload) {
      return new Promise((resolve, reject) => {
        const transmissionGroup = payload.transmissionID

        if (!transmissionGroup) {
          reject({ "message": "common.error.transmission_not_selected" })
        }

        const _env = localStorage.getItem(`currentEnv-${transmissionGroup}`)
        
        if (!_env) {
          reject({ "message": "common.error.env_not_selected" })
        }

        const env = JSON.parse(_env);
        
        let overwrite = "";
        if(payload.overwrite) {
          overwrite = "w=true";
        }

        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/import/${env.id}?${overwrite}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

        const postData = {
          "data": {
            "script": payload.script
          }
        }

       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      })
    },
    sendScriptTest(_, payload) {
      let env = JSON.parse(localStorage.getItem(`currentEnv-${payload.transmissionID}`));
      let data = {
        "event_id": payload.eventID,
        "sender_id": payload.senderID,
        "receiver_id": payload.receiverID,
        "data": payload.data?.body,
        "method": payload.data?.method,
        "header": payload.data?.header,
        "query": payload.data?.query,
        "path": payload.data?.path,
      }
      
      return axiosCall({
        url: `/central/${payload.transmissionID}/script/test/${env.id}/event`,
        method: 'post',
        payload: data,
      })
      
    },
    getPanelVars({commit}, payload) {
      const data = {
        "categories": payload.categories
      }
      
      return axiosCall({
        url: `/${payload.transmission_id}/transmission/variable/by/category`,
        method: 'post',
        payload: data
      })
      .then((resp)=>{
        commit('SET_VARIABLES_PANNEL', resp)
        return resp;
      })
    },
    getTransmissionOverview({commit}, transmissionID){
      return axiosCall({
        url: `/${transmissionID}/central_transmission/overview`,
        method: "get",
      })
    },
    async getTransmissionOrganizationForDashboard({ dispatch, commit }, payload ) {
      if (!payload.organization){
        throw ("Informe o ID da organização")
      }
      let orgId = String(payload.organization)
      let data = orgId ? { value: orgId } : resolve([]);

      let url = await dispatch("resolveUrl", {
        url: `/central/central_transmission/by/organization`,
        params: data,
      })

      return axiosCall({
        url: url,
        method: 'get',
      }).then((resp) => {
        let items = resp.items;
        return items;
      })
    },
  },
};
